import { useEffect, useState, useCallback } from 'react';
import {
  Dialog,
  useMediaQuery,
  useTheme,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Button,
  TextField,
  InputAdornment,
  Typography,
  makeStyles
} from '@material-ui/core';
// Autocomplete component
import { Autocomplete } from '@material-ui/lab';
import { DialogActions, DialogContent } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { withSnackbar } from 'notistack';
import {
  getMaterialLevelInfo,
  getMaterialLevelInfoPTE
} from 'services/materials';

import styles from './styles';

const useStyles = makeStyles(styles);

const UpdateTopicDialogue = ({ onClose, onSubmit, auth, enqueueSnackbar }) => {
  const theme = useTheme();
  const classes = useStyles();
  const [open] = useState(true);
  const [material, setMaterial] = useState('');
  const [course, setCourse] = useState('');
  const [lesson, setLesson] = useState(null);
  const [materialOptions, setMaterialOptions] = useState([]);
  const [courseOptions, setCourseOptions] = useState([]);
  const [lessonOptions, setLessonOptions] = useState([]);
  const [loadingMaterials, setLoadingMaterials] = useState(false);
  const [loadingCourses, setLoadingCourses] = useState(false);
  const [errors] = useState({});

  useEffect(() => {
    const fetchMaterials = async () => {
      setLoadingMaterials(true);
      try {
        const materials = await auth.api.materials.getMaterials();
        setMaterialOptions(materials);
        if (materials.length > 0) {
          setMaterial(materials[0].id);
          fetchCourses(materials[0].id);
        } else {
          setMaterial('');
        }
      } catch (error) {
        enqueueSnackbar('Failed to load materials. Please try again later.', {
          variant: 'error'
        });
      } finally {
        setLoadingMaterials(false);
      }
    };

    fetchMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  const fetchCourses = useCallback(
    async (materialId) => {
      setLoadingCourses(true);
      try {
        const courses = await auth.api.materials.getCourses(materialId);
        setCourseOptions(courses);
        if (courses.length > 0) {
          setCourse(courses[0].id);
          setLessonOptions(courses[0].lessons || []);
          setLesson(null);
        } else {
          setCourse('');
        }
      } catch (error) {
        enqueueSnackbar('Failed to load courses. Please try again later.', {
          variant: 'error'
        });
      } finally {
        setLoadingCourses(false);
      }
    },
    [auth, enqueueSnackbar]
  );

  const handleMaterialChange = (event) => {
    const newMaterial = event.target.value;
    setMaterial(newMaterial);
    setCourse('');
    setLesson(null);
    setLessonOptions([]);
    fetchCourses(newMaterial);
  };

  const handleCourseChange = (event) => {
    const newCourse = event.target.value;
    setCourse(newCourse);
    const selectedCourse = courseOptions.find(
      (course) => course.id === newCourse
    );
    setLessonOptions(selectedCourse ? selectedCourse.lessons : []);
    setLesson(null);
  };

  const handleLessonChange = (event, newValue) => {
    setLesson(newValue);
  };

  const handleSubmit = () => {
    if (lesson) {
      const topicData = {
        topic: {
          course,
          lesson: lesson.id,
          material
        }
      };

      onSubmit(topicData);
      onClose();
    } else {
      enqueueSnackbar('Please select a lesson.', { variant: 'warning' });
    }
  };
  const isA2 = lessonOptions?.some((lesson) => lesson.code.includes('AA2'));
  return (
    <Dialog
      fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <div className={classes.header}>
        <Typography variant="h3">Update User Details</Typography>
      </div>

      <DialogContent>
        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel id="select-material">Material</InputLabel>
          <Select
            labelId="select-material"
            value={material}
            onChange={handleMaterialChange}
            disabled={loadingMaterials}>
            {loadingMaterials ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              materialOptions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <FormControl variant="filled" fullWidth margin="normal">
          <InputLabel id="select-course">Course</InputLabel>
          <Select
            labelId="select-course"
            value={course}
            onChange={handleCourseChange}
            disabled={loadingCourses || !courseOptions.length}>
            {loadingCourses ? (
              <MenuItem disabled>
                <CircularProgress size={24} />
              </MenuItem>
            ) : (
              courseOptions.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))
            )}
          </Select>
        </FormControl>

        <Autocomplete
          style={{ marginTop: '20px' }}
          options={lessonOptions}
          getOptionLabel={(option) => option.name}
          value={lesson}
          onChange={handleLessonChange}
          groupBy={(option) =>
            `Level ${option.level} - ${
              material == 'pte'
                ? getMaterialLevelInfoPTE(isA2, option.level).name
                : getMaterialLevelInfo(option.level).name
            }`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="Lesson Topic"
              variant="outlined"
              error={Boolean(errors.topic)}
              helperText={errors.topic ? errors.topic[0] : null}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          disabled={!lesson}>
          Update Topic
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withSnackbar(UpdateTopicDialogue);
