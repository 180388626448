import { PureComponent } from 'react';

// Material UI imports
import { Divider, Typography, withStyles } from '@material-ui/core';

// Tab container
import { TabContainer } from 'components';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

// Behaviour view
import BehaviourView from '../../_views/BehaviourView';

// Component styles
import styles from './styles';

class ViewFeedbackDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      booking: undefined
    };

    // Bind component functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };
  }

  // Create a open function to hook into in
  // the parent component
  open(booking) {
    this.setState({
      open: true,
      booking
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  confirm() {
    // Close the modal
    this.close();
    const { booking } = this.state;
    const { onViewFeedback } = this.props;
    if (onViewFeedback) {
      onViewFeedback(booking);
    }
  }

  render() {
    // Grab the component's state
    const { open, booking } = this.state;
    console.log('groupbooking', booking);
    const feedback = booking && booking.feedback;
    const isGroupBooking = booking?.type === 'group';
    // Grab the auth provider from the props
    const { classes, auth } = this.props;
    const isPte =
      (booking?.tutor?.isPte ?? booking?.profile?.tutor?.isPte) &&
      booking?.info?.topic?.material === 'pte';

    // Define the tabs to be rendered
    const tabs = [
      {
        label: 'feedback',
        component: (
          <>
            {isGroupBooking ? (
              // Display feedback for all students in group session
              booking.students
                .filter((f) => {
                  if (auth.role === 'student') {
                    return f.id === auth.user.uid;
                  } else {
                    return f;
                  }
                })
                .map((student, index) => (
                  <div key={student.id} style={{ marginBottom: '20px' }}>
                    <Typography variant="h4" style={{ marginBottom: '10px' }}>
                      {student.name} Feedback
                    </Typography>
                    {student.feedback ? (
                      <>
                        {student?.feedback?.pronunciation ? (
                          <>
                            <Typography variant="h5">Pronunciation</Typography>
                            <Typography>
                              {student?.feedback?.pronunciation}
                            </Typography>
                          </>
                        ) : null}
                        {student?.feedback?.vocabulary ? (
                          <>
                            <Typography variant="h5">Vocabulary</Typography>
                            <Typography>
                              {student?.feedback?.vocabulary}
                            </Typography>
                          </>
                        ) : null}
                        {student?.feedback?.grammar ? (
                          <>
                            <Typography variant="h5">Grammar</Typography>
                            <Typography>
                              {student?.feedback?.grammar}
                            </Typography>
                          </>
                        ) : null}

                        {student?.feedback?.reading ? (
                          <>
                            <Typography variant="h5">Reading</Typography>
                            <Typography>
                              {student?.feedback?.reading}
                            </Typography>
                          </>
                        ) : null}
                        {student?.feedback?.speaking ? (
                          <>
                            <Typography variant="h5">Speaking</Typography>
                            <Typography>
                              {student?.feedback?.speaking}
                            </Typography>
                          </>
                        ) : null}
                        {student?.feedback?.writing ? (
                          <>
                            <Typography variant="h5">Writing</Typography>
                            <Typography>
                              {student?.feedback?.writing}
                            </Typography>
                          </>
                        ) : null}
                        {student?.feedback?.listening ? (
                          <>
                            <Typography variant="h5">Listening</Typography>
                            <Typography>
                              {student?.feedback?.listening}
                            </Typography>
                          </>
                        ) : null}
                      </>
                    ) : (
                      <Typography color="textSecondary">
                        No feedback available for this student
                      </Typography>
                    )}
                    {index < booking.students.length - 1 && (
                      <Divider style={{ margin: '20px 0' }} />
                    )}
                  </div>
                ))
            ) : (
              // Display regular single student feedback
              <>
                {booking?.feedback?.pronunciation ? (
                  <>
                    <Typography variant="h5">Pronunciation</Typography>
                    <Typography>{booking?.feedback?.pronunciation}</Typography>
                  </>
                ) : null}
                {booking?.feedback?.vocabulary ? (
                  <>
                    <Typography variant="h5">Vocabulary</Typography>
                    <Typography>{booking?.feedback?.vocabulary}</Typography>
                  </>
                ) : null}
                {booking?.feedback?.grammar ? (
                  <>
                    <Typography variant="h5">Grammar</Typography>
                    <Typography>{booking?.feedback?.grammar}</Typography>
                  </>
                ) : null}

                {booking?.feedback?.reading ? (
                  <>
                    <Typography variant="h5">Reading</Typography>
                    <Typography>{booking?.feedback?.reading}</Typography>
                  </>
                ) : null}
                {booking?.feedback?.speaking ? (
                  <>
                    <Typography variant="h5">Speaking</Typography>
                    <Typography>{booking?.feedback?.speaking}</Typography>
                  </>
                ) : null}
                {booking?.feedback?.writing ? (
                  <>
                    <Typography variant="h5">Writing</Typography>
                    <Typography>{booking?.feedback?.writing}</Typography>
                  </>
                ) : null}
                {booking?.feedback?.listening ? (
                  <>
                    <Typography variant="h5">Listening</Typography>
                    <Typography>{booking?.feedback?.listening}</Typography>
                  </>
                ) : null}
                {/* {booking?.feedback?.behaviour ? (
                  <>
                    <Typography variant="h5">Behaviour</Typography>
                    <Typography>{booking?.feedback?.behaviour}</Typography>
                  </>
                ) : null} */}
              </>
            )}
          </>
        )
      }
    ];

    // Add the hidden behaviour tab if we're a tutor
    if ((auth.role === 'tutor' || auth.role === 'admin') && !isPte) {
      tabs.push({
        label: 'behaviour',
        component: (
          <BehaviourView booking={booking} isGroupBooking={isGroupBooking} />
        )
      });
    }

    // Render the dialog
    return (
      <ResponsiveDialog
        title="Lesson Feedback"
        events={this.events}
        open={open}
        confirmText="okay"
        hideCancelButton>
        <TabContainer tabs={tabs} />
      </ResponsiveDialog>
    );
  }
}

export default withStyles(styles)(ViewFeedbackDialog);
