import API from '.';

interface StripeSessionID {
  id: string;
}

interface BillingHistoryItem {
  plan: string;
  id: string;
  amount: number;
  currency: string;
  created: number;
  description: string;
  card: {
    brand: string;
    last4: number;
  };
  receipt: string;
  status: string;
  subsriptionStatus: string;
}

interface APIPayments {
  /**
   * API endpoint for purchasing tokens
   */
  purchaseTokens: (amount: number) => Promise<StripeSessionID>;
  /**
   * API endpoint for purchasing a subscription
   */
  purchaseSubscription: (plan: string) => Promise<StripeSessionID>;
  /**
   * API endpoint for purchasing a group subscription
   */

  purchaseGroupSubscription: (plan: string) => Promise<StripeSessionID>;

  /**
   * API endpoint for cancelling a subscription
   */
  cancelSubscription: (subscription: string) => Promise<void>;
  /**
   * API endpoint for retrieving a user's billing history
   */
  getBillingHistory: (user: string) => Promise<BillingHistoryItem[]>;
  /**
   * API endpoint for availing a free trial
   */
  availFreeTrial: (
    priceId: string,

    promoCode: string
  ) => Promise<{ sessionId?: string; message?: string }>;
}

function Payments(self: API): APIPayments {
  const availFreeTrial = async (
    priceId: string,
    promoCode: string
  ): Promise<{ sessionId?: string; message?: string }> => {
    self.enforceRole('student');

    // Make the HTTP request to the '/free-trial' endpoint
    return await self._http(
      `payments/free-trial`,
      'POST',
      { data: { priceId, promoCode } },
      false
    );
  };

  const purchaseTokens = async (amount: number): Promise<StripeSessionID> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    // Run the HTTP function
    return self._http<StripeSessionID>(
      `payments/tokens/${amount}`,
      'POST',
      {},
      false
    );
  };

  const purchaseSubscription = async (
    plan: string
  ): Promise<StripeSessionID> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    console.log('purchase Subscription', plan);

    // Run the HTTP function
    return self._http<StripeSessionID>(
      `payments/subscribe/${plan}`,
      'POST',
      {},
      false
    );
  };

  const purchaseGroupSubscription = async (
    plan: string
  ): Promise<StripeSessionID> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    // Run the HTTP function
    return self._http<StripeSessionID>(
      `payments/groupsessionsubscription/${plan}`,
      'POST',
      {},
      false
    );
  };

  const cancelSubscription = async (subscription: string): Promise<void> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    // Make the request and get the response
    return self._http<void>(
      `payments/${subscription}/cancel`,
      'POST',
      {},
      false
    );
  };

  const getBillingHistory = async (
    user: string
  ): Promise<BillingHistoryItem[]> => {
    // Check whether we're the right role before making the request
    self.enforceRole('student');

    // Run the HTTP function
    return self._http<BillingHistoryItem[]>(
      `payments/history/${user}`,
      'GET',
      {},
      false
    );
  };

  // Return the endpoint functions
  return {
    purchaseTokens,
    purchaseSubscription,
    purchaseGroupSubscription,
    cancelSubscription,
    getBillingHistory,
    availFreeTrial
  };
}

export default Payments;
export type { StripeSessionID, BillingHistoryItem };
