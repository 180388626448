import { PureComponent, createRef } from 'react';

// Material UI components / helpers
import { withStyles } from '@material-ui/core';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Authentication
import { withAuth } from 'services/auth';

// Export cleanup
import compose from 'recompose/compose';

// Import the tab views
import {
  HistoryBookingsView,
  UpcomingBookingsView,
  ViewFeedbackDialog
} from './components';

// Bookings search component
import SearchView from 'views/student/Schedule';

// Router props
import { withRouter } from 'react-router';

// So we can parse the initial view prop
import queryString from 'query-string';

// Component styles
import styles from './styles';
import Box from '@mui/material/Box/Box';

class Bookings extends PureComponent {
  constructor(props) {
    super(props);

    // Grab the auth provider from the props
    const { auth, location } = props;

    // View feedback dialog ref
    this.viewFeedbackDialog = createRef();

    // Create an array of the tab names to populate the tab container
    const tabLabels = ['upcoming', 'history'];
    if (auth.role === 'student') tabLabels.unshift('search');

    // Parse the query string
    const parsedView = queryString.parse(location.search).view;

    // Determine the initial tabIndex
    let tabIndex = 0; // Default to upcoming
    if (location.state?.isHistory) {
      tabIndex = 1; // If history is in the state, select history tab
    } else if (parsedView && tabLabels.includes(parsedView)) {
      tabIndex = tabLabels.indexOf(parsedView); // Set tabIndex based on the parsed view
    }

    // Initialize bookings state
    this.state = {
      tabIndex: tabIndex,
      tabLabels,
      isAnimating: false // New state for animation control
    };
  }

  // Method to update tabIndex based on selected pill

  handleCurrentTab = (index) => {
    // Update the tab index to the new selected index
    this.setState({ isAnimating: true }, () => {
      setTimeout(() => {
        this.setState({ tabIndex: index, isAnimating: false });
      }, 300); // Duration matches the CSS animation time
    });
  };

  render() {
    const { auth, classes } = this.props;
    const { tabIndex, tabLabels } = this.state;

    // Define the Tab views for each label
    const tabComponents = {
      search: (
        <SearchView
          onUpdateTab={(index) => this.setState({ tabIndex: index })}
        />
      ),
      upcoming: (
        <UpcomingBookingsView
          onUpdateTab={(index) => this.setState({ tabIndex: index })}
          onViewFeedback={(booking) => {
            this.viewFeedbackDialog.current.open(booking);
          }}
        />
      ),
      history: (
        <HistoryBookingsView
          onUpdateTab={(index) => this.setState({ tabIndex: index })}
          onViewFeedback={(booking) => {
            this.viewFeedbackDialog.current.open(booking);
          }}
        />
      )
    };

    return (
      <>
        <ViewFeedbackDialog auth={auth} ref={this.viewFeedbackDialog} />
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className={classes.pillContainer}
          style={{ width: '100%' }}>
          {tabLabels.map((label, index) => {
            let pillClass = '';

            // Determine which class to apply based on the index
            if (index === 0) {
              pillClass =
                tabIndex === index
                  ? classes.leftPillSelected
                  : classes.leftPill;
            } else if (index === tabLabels.length - 1) {
              pillClass =
                tabIndex === index
                  ? classes.rightPillSelected
                  : classes.rightPill;
            } else {
              pillClass =
                tabIndex === index
                  ? classes.middlePillSelected
                  : classes.middlePill;
            }

            // Add animation class if animating
            if (this.state.isAnimating) {
              pillClass += ` ${classes.animatePill}`;
            }

            return (
              <div
                key={label}
                className={pillClass}
                onClick={() => this.handleCurrentTab(index)}>
                {label.charAt(0).toUpperCase() + label.slice(1)}
              </div>
            );
          })}
        </Box>

        <div className={classes.root}>
          {/* Conditionally render the content based on the selected tab */}
          {tabComponents[tabLabels[tabIndex]]}
        </div>
      </>
    );
  }
}

export default compose(
  withRouter,
  withSnackbar,
  withAuth,
  withStyles(styles)
)(Bookings);
