import { useState, useEffect, useContext, useCallback, useRef } from 'react';

// Material UI imports
import { makeStyles } from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Snackbar provicer
import { useSnackbar } from 'notistack';

// Authentication context
import { AuthContext } from 'services/auth';

// AsyncStatus
import { AsyncStatus } from 'components';

// Style
import styles from './styles';

import useWindowSize from 'hooks/useWindowSize';

const useStyles = makeStyles(styles);

export const MaterialsPanel = (props) => {
  const [frameLoaded, setFrameLoaded] = useState(false);
  const [url, setUrl] = useState(null);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(false);
  const [iframeError, setIframeError] = useState(false);
  const iframeRef = useRef(null);
  const { path, info } = props;

  const [width] = useWindowSize();
  const snackbar = useSnackbar();
  const auth = useContext(AuthContext);
  const classes = useStyles();

  const getUrl = async (path) => {
    try {
      const docUrl = await auth.api.materials.getLessonDocument(
        snackbar.enqueueSnackbar,
        path
      );

      setUrl(docUrl);
      setFrameLoaded(false);
      setIframeError(false); // Reset iframe error on successful URL fetch
    } catch (error) {
      setError(error.message || 'Failed to fetch document URL');
    }
  };

  useEffect(() => {
    async function getMaterialUrl() {
      try {
        setUrl(null);
        setFrameLoaded(false);

        if (path) {
          await getUrl(path);
        } else if (info) {
          const allCourses = await auth.api.materials.getCourses(
            info.topic.material
          );

          const course = allCourses.find(
            (entry) => entry.id === info.topic.course
          );
          if (!course) throw new Error('Course not found');

          const lesson = course.lessons.find(
            (lesson) => lesson.id === info.topic.lesson
          );
          if (!lesson) throw new Error('Lesson not found');

          await getUrl(lesson[`${auth.role}Resource`]);
        }
      } catch (error) {
        setError(error.message || 'Error fetching materials');
      }
    }

    getMaterialUrl();
  }, [retry]);

  const retryAPICall = useCallback(() => {
    setError(null);
    setRetry((prev) => !prev);
  }, []);

  const onFrameLoad = () => {
    if (url) {
      setFrameLoaded(true);
      setIframeError(false);
    }
  };

  const onFrameError = () => {
    setIframeError(true);
    console.log('Frame error retrying...');
    setTimeout(() => {
      setRetry((prev) => !prev);
    }, 2000); // Retry after 2 seconds
  };

  useEffect(() => {
    if (frameLoaded && iframeRef.current) {
      try {
        const iframe = iframeRef.current;
        iframe.onload = function () {
          try {
            const iframeDocument =
              iframe.contentDocument || iframe.contentWindow.document;

            const script = iframeDocument.createElement('script');
            script.textContent = `
              document.addEventListener('click', function(e) {
                if (e.target.tagName === 'A') {
                  e.preventDefault();
                  window.open(e.target.href, '_blank');
                }
              }, true);
            `;
            iframeDocument.body.appendChild(script);
          } catch (e) {
            console.log('Cross-origin restriction prevented script injection');
          }
        };
      } catch (e) {
        console.error('Error injecting script:', e);
      }
    }
  }, [frameLoaded]);

  if (error) {
    return (
      <div className={classes.status}>
        <AsyncStatus error={error} onRetry={retryAPICall} retry />
      </div>
    );
  }

  console.log('url==>>', url);

  return (
    <div className={width > 900 ? classes.root : classes.rootResponsive}>
      {width < 900 ? (
        <div
          className="pdf-container"
          style={{
            height: url ? '50vh' : '64vh',
            minWidth: width < 500 ? width - 10 : width
          }}>
          {url && !iframeError && (
            <iframe
              ref={iframeRef}
              width={width < 500 ? width - 10 : width}
              height={422}
              title="Lesson Materials"
              src={url}
              frameBorder="0"
              onLoad={onFrameLoad}
              onError={onFrameError} // Handle iframe load failures
            />
          )}
        </div>
      ) : (
        <>
          <Skeleton
            variant="rect"
            className={!frameLoaded ? classes.viewer : classes.hidden}
          />
          {url && !iframeError && (
            <iframe
              ref={iframeRef}
              className={frameLoaded ? classes.viewer : classes.hidden}
              title="Lesson Materials"
              src={url}
              frameBorder="0"
              onLoad={onFrameLoad}
              onError={onFrameError} // Handle iframe load failures
            />
          )}
        </>
      )}
    </div>
  );
};
