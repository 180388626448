import { useContext, forwardRef } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';

// Externals
import classNames from 'classnames';

// Authentication provider & Firebase
import { AuthContext } from 'services/auth';

// Shared helpers
import { getInitials } from 'helpers';

// Material components
import {
  // Button,
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
  // CircularProgress,
  Chip,
  withStyles
} from '@material-ui/core';

// Info icon
import {
  InfoRounded as InfoIcon,
  BugReportRounded as BugIcon,
  QuestionAnswerRounded as FAQ
} from '@mui/icons-material';

// Material UI lab
import { Skeleton } from '@material-ui/lab';

// Sidebar items
import getSidebarItems from 'services/views';

// Cleanup exports
import compose from 'recompose/compose';

// App config
import AppConfig from 'config';

// Component styles
import styles from './styles';
import { DateTime } from 'luxon';

function Sidebar(props) {
  // Grab values out of the component's properties
  const {
    classes,
    className,
    itemClicked,
    profile,
    profileError // ,
    // onProfileRetry
  } = props;

  const auth = useContext(AuthContext);

  const { displayName, photoURL } = auth.user;

  // Get the root classname
  const rootClassName = classNames(classes.root, className);

  // Create a ref component for the sidebar links
  // eslint-disable-next-line react/display-name
  const SidebarNavLink = forwardRef((newProps, ref) => (
    <NavLink onClick={itemClicked} innerRef={ref} {...newProps} />
  ));

  return (
    <nav className={rootClassName}>
      <div className={classes.logoWrapper}>
        <Link className={classes.logoLink} to={`/${auth.role}/dashboard`}>
          <img
            width="75px"
            height="75px"
            alt="e-English logo"
            className={classes.logoImage}
            src="/images/logos/new-logo.png"
          />
        </Link>
      </div>
      <Divider className={classes.logoDivider} />
      <div className={classes.profile}>
        <Avatar alt={displayName} className={classes.avatar} src={photoURL}>
          <Typography variant="h3" style={{ color: 'white' }}>
            {getInitials(displayName && displayName)}
          </Typography>
        </Avatar>
        <div className={classes.profileDetails}>
          <Typography className={classes.nameText} variant="h5">
            {displayName}
          </Typography>
          <Typography className={classes.bioText} variant="caption">
            {
              {
                student: 'Student',
                tutor: 'Teacher',
                admin: 'Administrator'
              }[auth.role]
            }
          </Typography>
        </div>
      </div>
      <Divider className={classes.profileDivider} />
      {(() => {
        // Make sure we're a student
        if (auth.role === 'student') {
          if (profileError) {
            return (
              <div className={classes.tokenInfo}>
                <Typography variant="h6">{profileError}</Typography>
                <Divider className={classes.profileDivider} />
              </div>
            );
          }

          if (!profile) {
            return (
              <>
                <div className={classes.tokenInfo}>
                  <Skeleton className={classes.skeletonChip}>
                    <Chip size="small" label="10 Lesson Tokens" />
                  </Skeleton>
                  <Skeleton
                    className={`${classes.skeletonChip} ${classes.subBadge}`}>
                    <Chip size="small" label="No Subscription" />
                  </Skeleton>
                </div>
                <Divider className={classes.profileDivider} />
              </>
            );
          }

          if (profile) {
            const sub = profile.stripe.subscription;
            const freeTrialActivated = profile.stripe.freeTrialActivated;

            // Mapping of price IDs to their corresponding categories
            const subscriptionCategories = {
              oneOnOneWorkPlace: 'price_1R8FFpHns6zXPgc8foke6G2G',
              oneOnOnePte: 'price_1R8FFKHns6zXPgc8kKOGDrF3',
              oneOnOnePteA2: 'price_1R8FEuHns6zXPgc8VxEUp2Fs',
              oneOnOnePteB2: 'price_1R8FF8Hns6zXPgc89ZUG51jJ'
              // groupWorkPlace: 'price_1QVCVoHns6zXPgc8xVuXjOnu',
              // groupPte: 'price_1QVCWmHns6zXPgc8FVBDzXEl',
              // groupPteA2: 'price_1R4IerHns6zXPgc8RBjX0DfX',
              // groupPteB2: 'price_1R4He5Hns6zXPgc8fCOkH7Mt'
            };

            // Find subscriptions based on price IDs
            const findSubscription = (priceId) =>
              sub?.currentSubscription?.categories?.find(
                (f) => f.priceId === priceId
              );

            // Render subscription section
            const renderSubscriptionSection = (title, subscriptions) => {
              const validSubscriptions = subscriptions.filter(
                (sub) => sub.category && profile?.data?.[sub.tokenKey] > 0
              );

              if (validSubscriptions.length === 0) return null;

              return (
                <div
                  className={classes.subscription}
                  style={title === 'PTE English' ? { marginTop: '10px' } : {}}>
                  <div className={classes.subscriptionTitle}>
                    <Typography variant="h5">{title}</Typography>
                  </div>
                  {validSubscriptions.map((sub, index) => (
                    <>
                      {index > 0 && <Divider className={classes.subdevider} />}
                      <Typography
                        className={classes.subscription_name}
                        variant="h6">
                        {profile.data[sub.tokenKey] || 0} {sub.label}
                      </Typography>
                      <Typography variant="p" color="textSecondary">
                        Expires{' '}
                        {sub.category?.expiredAt &&
                          DateTime.fromSeconds(sub.category.expiredAt).toFormat(
                            'dd LLL yyyy'
                          )}
                      </Typography>
                    </>
                  ))}
                </div>
              );
            };

            return (
              <>
                <div className={classes.tokenInfo}>
                  {sub?.currentSubscription &&
                  sub?.currentSubscription?.categories?.length > 0 ? (
                    <>
                      {renderSubscriptionSection('Workplace English', [
                        {
                          category: findSubscription(
                            subscriptionCategories.oneOnOneWorkPlace
                          ),
                          tokenKey: 'lessonTokens',
                          label: 'One-on-One Lessons'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.groupWorkPlace
                          ),
                          tokenKey: 'groupLessonTokens',
                          label: 'Group Sessions'
                        }
                      ])}

                      {renderSubscriptionSection('PTE English', [
                        {
                          category: findSubscription(
                            subscriptionCategories.oneOnOnePteA2
                          ),
                          tokenKey: 'A2PTELessonTokens',
                          label: 'One-on-One PTE A2 Lessons'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.oneOnOnePte
                          ),
                          tokenKey: 'PTELessonTokens',
                          label: 'One-on-One PTE B1 Lessons'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.oneOnOnePteB2
                          ),
                          tokenKey: 'B2PTELessonTokens',
                          label: 'One-on-One PTE B2 Lessons'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.groupPteA2
                          ),
                          tokenKey: 'A2PTEGroupLessonTokens',
                          label: 'Group Sessions PTE A2'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.groupPte
                          ),
                          tokenKey: 'PTEGroupLessonTokens',
                          label: 'Group Sessions PTE B1'
                        },
                        {
                          category: findSubscription(
                            subscriptionCategories.groupPteB2
                          ),
                          tokenKey: 'B2PTEGroupLessonTokens',
                          label: 'Group Sessions PTE B2'
                        }
                      ])}
                    </>
                  ) : freeTrialActivated ? (
                    <Chip
                      size="small"
                      className={`${classes.infoChip}`}
                      label="Free Trial"
                    />
                  ) : (
                    <Chip
                      size="small"
                      className={`${classes.infoChip}`}
                      label="No Subscription"
                    />
                  )}
                </div>
                <Divider className={classes.profileDivider} />
              </>
            );
          }
        }

        return null;
      })()}
      <List component="div" disablePadding>
        {getSidebarItems(auth.role, auth.isDevBuild).map((item) => {
          if (item.icon !== undefined && item.text !== undefined) {
            return (
              <ListItem
                activeClassName={classes.activeListItem}
                className={classes.listItem}
                component={SidebarNavLink}
                to={item.route}
                key={item.route}>
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  classes={{ primary: classes.listItemText }}
                  primary={item.text}
                />
              </ListItem>
            );
          }
          return null;
        })}
      </List>
      <Divider className={classes.listDivider} />
      <List
        component="div"
        disablePadding
        subheader={
          <ListSubheader className={classes.listSubheader}>
            Support
          </ListSubheader>
        }>
        <ListItem
          className={classes.listItem}
          component="a"
          href="mailto:support@e-english.com.au"
          target="_blank">
          <ListItemIcon className={classes.listItemIcon}>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.listItemText }}
            primary="Contact Us"
          />
        </ListItem>
        {auth.role !== 'admin' && (
          <ListItem
            className={classes.listItem}
            component="a"
            href={
              auth.role === 'tutor'
                ? 'https://docs.google.com/forms/d/e/1FAIpQLSc1abmrnw9R9l4-QqAUynwxwlPi2CCKhJHQjliy3luOoCZbsA/viewform?usp=sharing'
                : 'https://docs.google.com/forms/d/e/1FAIpQLSextGBWDBn1kgZWaKXG88eskrDceITjK5ryqbqCl2QNTjtTUQ/viewform?usp=sharing'
            }
            target="_blank">
            <ListItemIcon className={classes.listItemIcon}>
              <BugIcon />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="Submit feedback"
            />
          </ListItem>
        )}
        {auth.role == 'tutor' && (
          <ListItem
            className={classes.listItem}
            component="a"
            href={
              'https://firebasestorage.googleapis.com/v0/b/eng-tutor-app.appspot.com/o/tutorfaqs%2FFAQ%20TUTOR_v1.pdf?alt=media&token=b3d16971-ec0d-4878-a8cb-3397843698ac'
            }
            target="_blank">
            <ListItemIcon className={classes.listItemIcon}>
              <FAQ />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.listItemText }}
              primary="FAQ"
            />
          </ListItem>
        )}
      </List>
      <Divider className={classes.listDivider} />
      <ListSubheader className={classes.listVersionSubheader}>
        Build ID: {AppConfig.buildId}
      </ListSubheader>
    </nav>
  );
}

export default compose(withRouter, withStyles(styles))(Sidebar);
