import React, { PureComponent, Suspense } from 'react';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';

// 'Under Development' and 404 views
import UnderDevelopment from 'views/shared/UnderDevelopment';
import NotFound from 'views/shared/NotFound';
import Call from 'views/shared/Call';
import SpeedTest from 'views/tutor/SpeedTest';

// Snackbar notifications
import { withSnackbar } from 'notistack';

// Function that retrieves the view components
import getViewComponents from 'services/views';
import firebase from 'firebase/app';

// Dashboard layout & join card
import { Dashboard as DashboardLayout } from 'layouts';
import { JoinCard, PageLoader } from 'components';

class AuthRoutes extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the routes array and set the 'first' flag to true.
    this.routes = [];
    this.first = true;

    // Initialize the component's state
    this.state = {
      speedtestOutcome: false
    };

    this.isLessonTokensChanged = React.createRef(null);
    this.isGroupLessonTokenChanged = React.createRef(null);
    this.isPTELessonTokenChanged = React.createRef(null);
    this.isPTEGroupTokenChanged = React.createRef(null);
    this.isA2OneOnOneLessonTokenChanged = React.createRef(null);
    this.isA2GroupLessonTokenChanged = React.createRef(null);
    this.isBB2OneOnOneLessonTokenChanged = React.createRef(null);
    this.isBB2GroupLessonTokenChanged = React.createRef(null);

    // Bind all required functions
    this.speedtestRoute = this.speedtestRoute.bind(this);
    this.speedtestSuccess = this.speedtestSuccess.bind(this);

    // Define the toFirstUpper function
    this.toFirstUpper = (text) => {
      return text.charAt(0).toUpperCase() + text.slice(1);
    };
  }

  validRoute() {
    if (this.first) {
      this.first = false;
      const { initial, auth } = this.props;

      // Check for call route first
      if (initial.pathname === '/call') return '/call' + initial.search;

      // Split the current path into segments
      const pathSegments = initial.pathname.split('/').filter(Boolean);

      // Find matching route
      const matchingRoute = this.routes.find((route) => {
        const routeSegments = route.split('/').filter(Boolean);

        if (pathSegments.length !== routeSegments.length) return false;

        return routeSegments.every((segment, index) => {
          if (segment.startsWith(':')) return true; // Parameter segment matches anything
          return segment === pathSegments[index];
        });
      });

      if (matchingRoute) {
        return initial.pathname + initial.search;
      }

      // Redirect to dashboard if no match
      return `/${auth.role}/dashboard`;
    }

    return '/not-found';
  }
  speedtestRoute() {
    // Grab the authentication provider from the props
    const { auth } = this.props;

    // Return the speedtest route
    return <SpeedTest auth={auth} onSuccess={this.speedtestSuccess} />;
  }

  speedtestSuccess() {
    // Update the state to render the dashboard
    this.setState({
      speedtestOutcome: true
    });
  }

  componentDidMount() {
    let user = firebase.auth().currentUser;
    const { enqueueSnackbar } = this.props;
    if (user) {
      const docRef = firebase.firestore().collection('students').doc(user?.uid);
      docRef.onSnapshot((snapshot) => {
        // Define token types with their corresponding state refs and labels
        const tokenTypes = [
          {
            key: 'lessonTokens',
            ref: 'isLessonTokensChanged',
            label: 'Lesson '
          },
          {
            key: 'groupLessonTokens',
            ref: 'isGroupLessonTokenChanged',
            label: 'Group Lesson '
          },
          {
            key: 'PTELessonTokens',
            ref: 'isPTELessonTokenChanged',
            label: 'BB1 One on One Lesson '
          },
          {
            key: 'PTEGroupLessonTokens',
            ref: 'isPTEGroupTokenChanged',
            label: 'BB1 Group Lesson '
          },
          {
            key: 'A2PTELessonTokens',
            ref: 'isA2OneOnOneLessonTokenChanged',
            label: 'AA2 One on One Lesson '
          },
          {
            key: 'A2PTEGroupLessonTokens',
            ref: 'isA2GroupLessonTokenChanged',
            label: 'AA2 Group Lesson '
          },
          {
            key: 'BB2PTELessonTokens',
            ref: 'isBB2OneOnOneLessonTokenChanged',
            label: 'BB2 One on One Lesson '
          },
          {
            key: 'BB2PTEGroupLessonTokens',
            ref: 'isBB2GroupLessonTokenChanged',
            label: 'BB2 Group Lesson '
          }
        ];

        // Generic token change tracking function
        const trackTokenChanges = (tokenType) => {
          const currentTokens = snapshot?.data()?.data?.[tokenType.key];
          const prevTokensRef = this[tokenType.ref];

          if (
            prevTokensRef.current !== currentTokens &&
            typeof currentTokens !== 'undefined'
          ) {
            if (prevTokensRef.current !== null) {
              const totalTokens = Math.abs(
                prevTokensRef.current - currentTokens
              );

              if (prevTokensRef.current < currentTokens) {
                enqueueSnackbar(
                  `${totalTokens} ${tokenType.label} have been added in your account`
                );
              } else {
                enqueueSnackbar(
                  `${totalTokens} ${tokenType.label} have been removed from your account`
                );
              }
            }
            prevTokensRef.current = currentTokens;
          }
        };

        // Track changes for all token types
        tokenTypes.forEach(trackTokenChanges);
      });
    }
  }

  render() {
    // Grab the auth and location providers from our properties
    const { auth, initial, currentTheme, toggleDarkTheme } = this.props;
    let { location } = this.props;
    const { speedtestOutcome } = this.state;

    if (location.pathname === '/call') {
      return <Call />;
    }

    // Check if we're a tutor, and hence, need to complete a speed test
    if (auth.role === 'tutor' && speedtestOutcome === false) {
      return (
        <Switch>
          <Route component={this.speedtestRoute} exact path="/speed-test" />
          <Redirect to="/speed-test" />
        </Switch>
      );
    }

    // Return the authenticated view.
    return (
      <DashboardLayout
        title={`${this.toFirstUpper(auth.role)} Portal`}
        showBackButton={
          location.state && location.pathname.split('/').length > 3
        }
        initial={initial.pathname}
        currentTheme={currentTheme}
        toggleDarkTheme={toggleDarkTheme}>
        {['student', 'tutor'].includes(auth.role) && <JoinCard auth={auth} />}
        <Suspense fallback={<PageLoader />}>
          <Switch>
            {getViewComponents(auth.role, auth.isDevBuild).map((item) => {
              // Push the routes into our 'routes' property so we can use them
              // for validation within the this.validRoute() method
              this.routes.push(item.route);

              // Return the route.
              return (
                <Route
                  component={item.component}
                  path={item.route}
                  key={item.route}
                  exact
                />
              );
            })}
            <Route
              component={UnderDevelopment}
              exact
              path="/under-development"
            />
            <Route component={NotFound} exact path="/not-found" />
            <Redirect to={this.validRoute()} />
          </Switch>
        </Suspense>
      </DashboardLayout>
    );
  }
}

export default withSnackbar(withRouter(AuthRoutes));
