export default (theme) => ({
  root: {},
  avatar: {
    width: '85px',
    height: '85px',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1)
    }
  },
  subtitle: {
    color: theme.palette.text.secondary,
    fontSize: '12px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
    paddingBottom: '0px !important',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center'
    },
    [theme.breakpoints.up('lg')]: {
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center'
    }
  },
  date: {
    marginTop: theme.spacing(2),
    textAlign: 'left'
  },
  info: {
    margin: theme.spacing(1),
    textAlign: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    }
  },
  commencingBadge: {
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    textAlign: 'center',
    padding: '5px 0px'
  },
  commencingTime: {
    color: '#FFFFFF'
  },
  buttonFill: {
    display: 'flex',
    '& .MuiButton-root': {
      flex: '1 1 0'
    }
  }
});
