import firebase from 'firebase/app';
import 'firebase/firestore';
import API from '.';

// Live listener class
import Live from './_live';

interface Tutor {
  id?: string;
  profile: {
    dob: number;
    gender: 'Male' | 'Female' | 'Other';
    image: string;
    inroduction: string;
    name: string;
    nationality: string;
    school: string;
  };
  rating: {
    avg: number;
    num: number;
    raw: number[];
  };
}

interface Student {
  data: {
    favouriteTutors: string[];
    usedTutors: string[];
    bookingBlacklist: string[];
    lessonTokens: number;
    groupLessonTokens: number;
    PTELessonTokens: number;
    PTEGroupLessonTokens: number;
  };
  profile: {
    dob: number | null;
    gender: 'Male' | 'Female' | 'Other' | null;
    name: string;
    country: string;
  };
  stripe: {
    customer: string;
    subscription: {
      redeemedSubscriptions: string[];
      currentSubscription: {
        categories: {
          name: string;
          priceId: string;
          type: string;
          subscriptionId: string;
        }[];
        createdAt: string;
        name: string;
        tokensPerMonth: string | number;
        type: string;
        subscriptionId: string;
      } | null;
    } | null;
  };
}

interface Report {
  id: string;
  by: string;
  notes: string;
  reason: string;
  when: number;
}

interface Rating {
  id: string;
  by: string;
  hasMessage: boolean;
  message: string;
  rating: number;
  when: number;
}

class Profile extends Live {
  api: API;
  favUpdated: boolean;
  constructor(api: API) {
    super();

    // Setup a reference to the API
    this.api = api;
    this.favUpdated = true;
  }

  mount(): void {
    // Setup listeners
    if (this.api.credentials?.role === 'student') {
      this.listener(
        'profileLiveStudentProfile',
        firebase.firestore().doc(`students/${firebase.auth().currentUser?.uid}`)
      );
    }
  }

  /*
    API endpoint for getting a list of recommended tutors
  */
  async getRecommendedTutors(store = true): Promise<Tutor[]> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    return this.api._firebase<Tutor[]>(
      'profileGetRecommendedTutors',
      async () => {
        return (
          await firebase
            .firestore()
            .collection('tutors')
            .where('rating.avg', '>', 0)
            .limit(10)
            .get()
        ).docs.map((tutor) => ({ id: tutor.id, ...tutor.data() }));
      },
      store
    );
  }

  /*
    API endpoint for getting a list of recommended tutors
  */
  async getFavouriteTutors(store = true): Promise<Tutor[]> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    return this.api._firebase<Tutor[]>(
      'profileGetFavouriteTutors',
      async () => {
        const profile = await this.getStudentProfile();
        if (
          !profile.data.favouriteTutors ||
          profile.data.favouriteTutors.length === 0
        )
          return [];
        this.favUpdated = true;
        return (
          await firebase
            .firestore()
            .collection('tutors')
            .where(
              firebase.firestore.FieldPath.documentId(),
              'in',
              profile.data.favouriteTutors
            )
            .limit(10)
            .get()
        ).docs.map((tutor) => ({ id: tutor.id, ...tutor.data() }));
      },
      this.favUpdated && store
    );
  }

  /*
    API endpoint for getting a list of previous used tutors
  */
  async getUsedTutors(store = true): Promise<Tutor[]> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    return this.api._firebase<Tutor[]>(
      'profileGetUsedTutors',
      async () => {
        const profile = await this.getStudentProfile();
        if (!profile.data.usedTutors || profile.data.usedTutors.length === 0)
          return [];
        return (
          await firebase
            .firestore()
            .collection('tutors')
            .where(
              firebase.firestore.FieldPath.documentId(),
              'in',
              profile.data.usedTutors
            )
            .limit(10)
            .get()
        ).docs.map((tutor) => ({ id: tutor.id, ...tutor.data() }));
      },
      store
    );
  }

  /**
   * API endpoint for rating tutors
   */
  async rate(tutor: string, rating: 1 | 2 | 3 | 4 | 5): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    // Run the HTTP function
    return this.api._http<void>(
      `profile/tutor/${tutor}/rate`,
      'POST',
      { data: rating },
      false
    );
  }

  /**
   * API endpoint for getting a tutor's ratings
   */
  async getRatings(tutor: string, store = false): Promise<Rating[]> {
    return this.api._firebase<Rating[]>(
      'profileGetRatings',
      async () => {
        return (
          await firebase
            .firestore()
            .collection('tutors')
            .doc(tutor)
            .collection('ratings')
            .where('hasMessage', '==', true)
            .orderBy('when', 'desc')
            .limit(5)
            .get()
        ).docs.map((rating) => {
          return { id: rating.id, ...rating.data() };
        });
      },
      store
    );
  }

  /**
   * API endpoint for reporting a tutor
   */
  async report(tutor: string, data: Report): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    // Run the HTTP function
    return this.api._http<void>(
      `profile/tutor/${tutor}/report`,
      'POST',
      { data },
      false
    );
  }

  /**
   *  API endpoint for retrieving a student's profile
   */
  async getStudentProfile(uid?: string): Promise<Student> {
    const id = uid ? uid : firebase.auth().currentUser?.uid;
    return this.api._firebase<Student>(
      'profileGetStudentProfile',
      async () => {
        const student = this.initial(
          'profileLiveStudentProfile'
        ) as firebase.firestore.DocumentSnapshot;
        return student
          ? student.data()
          : (
              await firebase.firestore().collection('students').doc(id).get()
            ).data();
      },
      false
    );
  }

  async getAllStudents(store: false): Promise<any> {
    this.api.enforceRole('admin');

    return this.api._http(`profile/getAllStudents`, 'GET', {}, store);
  }

  /**
   * API endpoint for getting a tutor's profile
   */
  async getTutorProfile(tutor: string, store = true): Promise<Tutor | null> {
    return this.api._firebase<Tutor | null>(
      `profileGetTutorProfile-${tutor}`,
      async () => {
        const data = (
          await firebase.firestore().collection('tutors').doc(tutor).get()
        ).data();
        return data ? { id: tutor, ...data } : null;
      },
      store
    );
  }

  /**
   * API endpoint for toggling a tutor to a student's favourites
   */
  async toggleFavouriteTutor(tutor: string, favourite: boolean): Promise<void> {
    const { arrayUnion, arrayRemove } = firebase.firestore.FieldValue;

    this.favUpdated = false;

    return firebase
      .firestore()
      .collection('students')
      .doc(firebase.auth().currentUser?.uid)
      .update({
        'data.favouriteTutors': favourite
          ? arrayUnion(tutor)
          : arrayRemove(tutor)
      });
  }

  async updateStudentProfile(details: firebase.UserInfo): Promise<void> {
    // Check whether we're the right role before making the request
    this.api.enforceRole('student');

    // Run the HTTP function
    return this.api._http<void>(
      `profile/student/update`,
      'PUT',
      { data: details },
      false
    );
  }

  async confirmUpdateStudentProfile(
    details: firebase.UserInfo,
    expireTime: string
  ): Promise<void> {
    // Run the HTTP function
    return this.api._http<void>(
      `profile/student/confirmUpdate`,
      'PUT',
      {
        data: {
          details: details,
          expireTime: expireTime
        }
      },
      false
    );
  }

  // --- ADMIN ENDPOINTS ---

  /**
   * ADMIN API endpoint for getting all of a tutor's ratings
   */
  async adminGetRatings(
    tutor: string,
    startAt: number,
    endAt: number,
    store = false
  ): Promise<Rating[]> {
    return this.api._firebase<Rating[]>(
      `profileAdminGetRatings-${tutor}-${startAt}-${endAt}`,
      async () => {
        return (
          await firebase
            .firestore()
            .collection('tutors')
            .doc(tutor)
            .collection('ratings')
            .where('hasMessage', '==', true)
            .where('when', '>=', startAt)
            .where('when', '<=', endAt)
            .orderBy('when', 'desc')
            .get()
        ).docs.map((rating) => {
          return { id: rating.id, ...rating.data() };
        });
      },
      store
    );
  }

  /**
   * ADMIN API endpoint for getting a tutor's reports
   */
  async adminGetReports(
    tutor: string,
    startAt: number,
    endAt: number,
    store = false
  ): Promise<Report[]> {
    return this.api._firebase<Report[]>(
      `profileAdminGetReports-${tutor}-${startAt}-${endAt}`,
      async () => {
        return (
          (
            await firebase
              .firestore()
              .collection('tutors')
              .doc(tutor)
              .collection('reports')
              // .where('when', '>=', startAt)
              // .where('when', '<=', endAt)
              // .orderBy('when', 'desc')
              .get()
          ).docs.map((report) => {
            return { id: report.id, ...report.data() };
          })
        );
      },
      store
    );
  }

  /**
   * ADMIN API endpoint for getting aggregate tutor reports
   */
  async adminGetAllReports(store = false): Promise<Report[]> {
    return this.api._firebase<Report[]>(
      'profileAdminGetAllReports',
      async () => {
        return (
          await firebase
            .firestore()
            .collectionGroup('reports')
            .where('resolved', '==', 'false')
            .orderBy('when', 'desc')
            .get()
        ).docs.map((report) => {
          return { id: report.id, ...report.data() };
        });
      },
      store
    );
  }

  /**
   * ADMIN API endpoint for getting aggregate tutor reports
   */
  async adminResolveReport(
    tutor: string,
    report: string,
    action: string,
    resolved: boolean
  ): Promise<void> {
    await firebase
      .firestore()
      .collection('tutors')
      .doc(tutor)
      .collection('reports')
      .doc(report)
      .update({
        action,
        resolved
      });
  }
}

export default Profile;
export type { Tutor, Student, Report, Rating };
