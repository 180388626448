export default (theme) => ({
  root: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  avatar: {
    width: 100,
    height: 100,
    transition: 'opacity 200ms cubic-bezier(0, 1, 1, 1)',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer'
    }
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 0
    }
  }
});
