export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column'
  },
  pillContainer: {
    display: 'flex',
    padding: theme.spacing(4),
    textTransform: 'uppercase'
  },

  leftPill: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
    borderLeft: '1px solid #e4e4e4',
    borderRight: '1px solid #e4e4e4',
    borderRadius: '7px 0px 0px 7px'
  },
  leftPillSelected: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    cursor: 'pointer',
    color: '#ffffff',
    justifyContent: 'center',
    background: '#1a53ae',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '7px 0px 0px 7px'
  },
  middlePill: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
    borderLeft: '1px solid #e4e4e4',
    borderRight: '1px solid #e4e4e4'
  },
  middlePillSelected: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    cursor: 'pointer',
    justifyContent: 'center',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
    borderRight: '1px solid #e4e4e4',
    color: '#ffffff',
    background: '#1a53ae',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
  },
  rightPill: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '0px 7px 7px 0px',
    borderTop: '1px solid #e4e4e4',
    borderBottom: '1px solid #e4e4e4',
    borderRight: '1px solid #e4e4e4'
  },
  rightPillSelected: {
    minHeight: '39px',
    fontSize: '16px',
    minWidth: '113px',
    padding: '12px 22px',
    display: 'flex',
    cursor: 'pointer',
    color: '#ffffff',
    justifyContent: 'center',
    background: '#1a53ae',
    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    borderRadius: '0px 7px 7px 0px'
  }
});
