import { grey } from '@material-ui/core/colors';

interface MaterialLevelInfo {
  color: string;
  name: string;
}

const levelDescriptions: Record<string, string> = {
  Beginners:
    'Introducing vocabulary, basic sentence construction, foundational conversation skills.',
  Elementary:
    'Building on basic vocabulary, simple to moderate sentences, basic conversation practice.',
  'Pre-Intermediate':
    'Growing vocabulary, forming some complex sentences, conversational practice with moderate complexity.',
  'Intermediate I':
    'Expanding vocabulary, forming more complex sentences, conversational practice with increased complexity.',
  'Intermediate II':
    'Enriching vocabulary, refining complex sentence structures, engaging in more targeted conversations.',
  'Pre-Advanced': 'Description for Pre-Advanced level...',
  'Advanced I': 'Description for Advanced I level...',
  'Advanced II': 'Description for Advanced II level...',
  'Pre-Proficient': 'Description for Pre-Proficient level...',
  Proficient: 'Description for Proficient level...',
  'Unit 1':
    '<b>Introduction to Everyday English:</b> Build confidence in using the present simple and basic question forms to talk about personal information, daily routines, and simple conversations.',
  'Unit 2':
    '<b>Descriptions and Locations: </b>Learn to describe people, places, and possessions using adjectives, possessives, and prepositions of place in the present simple tense.',
  'Unit 3':
    '<b>Practical Communication:</b> Develop functional English for real-life scenarios like ordering food, giving advice, making polite requests, and shopping.',
  'Unit 4':
    '<b>Talking About the Past:</b> Explore how to describe personal experiences and events using the simple past and past continuous tenses.',
  'Unit 5':
    '<b>Future Tenses:</b> Practice planning and predicting future activities using the simple future and future continuous forms.',
  'Unit 6':
    '<b>Comparisons and Descriptions:</b> Strengthen descriptive language by learning comparatives, superlatives, and how to use adjectives and adverbs effectively.',

  'Unit 7':
    '<b>Hypotheticals and Conditionals:</b> Understand how to express real and imagined situations using zero, first, second, and mixed conditionals, plus personal preferences.',
  'Unit 8':
    '<b>Advanced Grammar and Fluency:</b> Master more advanced structures like reported speech, passive voice, phrasal verbs, and relative clauses to enhance fluency and clarity.',
  Listening:
    'The Listening Section boosts active listening, note-taking, and memory skills while targeting Fill-in-the-Blanks accuracy and identifying key ideas.',
  Reading:
    'The Reading Section enhances speed, comprehension, vocabulary, and critical thinking for reordering sentences and multiple-choice questions.',
  Speaking:
    'The Speaking Section improves pronunciation, fluency, and quick responses while refining summarization and descriptive skills for tasks like lecture re-tells.',
  Writing:
    'The Writing Section fosters essay structure, grammar, and summarization skills with strategies for time management and clarity.'
};

const getMaterialLevelInfo = (
  level: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10
): MaterialLevelInfo => {
  if (level === 1) {
    return {
      color: '#EAAD9A',
      name: 'Beginners'
    };
  } else if (level === 2) {
    return {
      color: '#EDB283',
      name: 'Elementary'
    };
  } else if (level === 3) {
    return {
      color: '#96E29C',
      name: 'Pre-Intermediate'
    };
  } else if (level === 4) {
    return {
      color: '#6EA672',
      name: 'Intermediate I'
    };
  } else if (level === 5) {
    return {
      color: '#4A734D',
      name: 'Intermediate II'
    };
  } else if (level === 6) {
    return {
      color: '#84DAEA',
      name: 'Pre-Advanced'
    };
  } else if (level === 7) {
    return {
      color: '#69AEBB',
      name: 'Advanced I'
    };
  } else if (level === 8) {
    return {
      color: '#487881',
      name: 'Advanced II'
    };
  } else if (level === 9) {
    return {
      color: '#C599E7',
      name: 'Pre-Proficient'
    };
  } else if (level === 10) {
    return {
      color: '#8C6EA2',
      name: 'Proficient'
    };
  }
  return {
    color: grey[500],
    name: 'Unknown'
  };
};
const getMaterialLevelInfoPTE = (
  isA2: boolean,
  level: number,
  showFullLevel?: boolean | undefined
): MaterialLevelInfo => {
  if (isA2) {
    const unitLevels = Array.from({ length: 8 }, (_, i) => ({
      color: [
        '#4A734D',
        '#84DAEA',
        '#69AEBB',
        '#487881',
        '#C599E7',
        '#8C6EA2',
        '#8C6EA2',
        '#8C6EA2'
      ][i],
      name: `Unit ${i + 1}`
    }));
    return unitLevels[level - 1] || { color: '#B0B0B0', name: 'Unknown' };
  } else {
    const levels = [
      { color: '#EAAD9A', name: showFullLevel ? 'Listening' : 'L' },
      { color: '#EDB283', name: showFullLevel ? 'Reading' : 'R' },
      { color: '#96E29C', name: showFullLevel ? 'Speaking' : 'S' },
      { color: '#6EA672', name: showFullLevel ? 'Writing' : 'W' }
    ];

    return levels[level - 1] || { color: '#B0B0B0', name: 'Unknown' };
  }
};

const renderClor = (status: string) => {
  switch (status) {
    case 'booked':
      return 'primary';
    case 'cancelled':
      return 'error';
    case 'open':
      return 'info';
    case 'tutor-absent':
      return 'error';
    case 'student-absent':
      return 'warning';
    case 'completed':
      return 'success';
    default:
      return 'default';
  }
};
const getMaterialLevelDescription = (level: number | string): string => {
  const levelKey = level.toString();
  return levelDescriptions[levelKey] || 'Unknown';
};

export {
  getMaterialLevelInfo,
  getMaterialLevelDescription,
  renderClor,
  getMaterialLevelInfoPTE
};
