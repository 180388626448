import { PureComponent } from 'react';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Material UI components / helpers
import { Button, Divider, Typography, withStyles } from '@material-ui/core';

// Skeleton component
import { Skeleton } from '@material-ui/lab';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Authentication
import { withAuth } from 'services/auth';

// Component styles
import styles from './styles';

class BookingInfo extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      material: undefined,
      course: undefined
    };

    // Set the ready flag to true
    this.ready = true;
  }

  async componentDidMount() {
    // Grab the materials endpoint from the props
    const { auth, info, onResource } = this.props;
    console.log('info', info);
    // Make sure we've been provided with the material info before making any requests
    if (!info) return;

    // Wrap in a try-catch to handle any errors
    try {
      // Retrieve the materials from the API
      const [material, course] = [
        (await auth.api.materials.getMaterials()).find(
          (entry) => entry.id === info.topic.material
        ),
        (await auth.api.materials.getCourses(info.topic.material)).find(
          (entry) => entry.id === info.topic.course
        )
      ];

      // Find the lesson
      const lesson = course?.lessons?.find(
        (lesson) => lesson.id === info.topic.lesson
      );

      // Update the state with the materials
      if (this.ready) {
        this.setState(
          {
            material,
            course,
            lesson
          },
          () => {
            // Trigger the resource handler
            if (onResource) onResource(lesson[`${auth.role}Resource`]);
          }
        );
      }
    } catch (error) {
      // If we're mounted, update the state with the error
      if (this.ready) {
        this.setState({
          error: getErrorMessage(error)
        });
      }
    }
  }

  componentWillUnmount() {
    // Update the ready flag to false
    this.ready = false;
  }

  render() {
    // Destructure the props, state
    const {
      classes,
      info,
      auth,
      enqueueSnackbar,
      hideOpenButton,
      booking,
      groupSession
    } = this.props;
    const { material, course, lesson, error } = this.state;

    // Destructure the booking information
    const { correctMistakes, introduction, notes } = info || {};

    // Ensure any errors have not occured

    if (error)
      return (
        <Typography variant="subtitle2" color="textSecondary">
          {error}
        </Typography>
      );

    // Render the booking information
    return (
      <div className={classes.details}>
        <div className={classes.detailsHeader}>
          {material ? (
            <>
              <div className={classes.detailsHeaderImgWrapper}>
                <img
                  className={classes.detailsHeaderImg}
                  src={material && material.icon}
                  alt=""
                />
              </div>
              <div className={classes.detailsHeaderInfo}>
                <Typography variant="h5">
                  <b>
                    {
                      // info.is_mock
                      //   ? 'Demo Lesson Plan'
                      //   :
                      material && material.name
                    }
                  </b>
                </Typography>
                <Typography color="textSecondary">
                  <b>
                    {' '}
                    {
                      // info.is_mock
                      //   ? 'Demo Lesson Plan'
                      //   :
                      course && `${course.name}`
                    }
                  </b>
                </Typography>
                <Typography color="textSecondary">
                  {
                    // info.is_mock
                    //   ? 'MAS208 Discussing Preferences'
                    //   :
                    lesson && `${lesson.code} - ${lesson.name}`
                  }
                </Typography>
                <Typography color="textSecondary">
                  {
                    // info.is_mock ? 'Level 2' :

                    lesson && `Level ${lesson.level}`
                  }
                </Typography>
                {!hideOpenButton && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className={classes.detailsHeaderButton}
                    onClick={() => {
                      // if (info.is_mock) {
                      //   auth.api.materials
                      //     .getLessonDocument(
                      //       enqueueSnackbar,
                      //       'materials/mock/Demo Lesson Plan - Tutor Training.pdf'
                      //     )
                      //     .then((val) => {
                      //       window.open(val, '_blank');
                      //     });
                      //   return;
                      // }

                      // Check if there is a booking and lesson
                      if (booking && lesson) {
                        // Check if the lesson id matches with the booking's topic.lesson
                        if (booking.info.topic.lesson === lesson.id) {
                          // Check if it's within the allowed time range (20 minutes before and 24 hours after)
                          const lessonStart =
                            auth.role === 'tutor'
                              ? booking.when - 12 * 60 * 60 * 1000 // 12 hors before
                              : booking.when - 20 * 60 * 1000;
                          const lessonEnd = booking.when + 24 * 60 * 60 * 1000;

                          if (
                            Date.now() >= lessonStart &&
                            Date.now() <= lessonEnd &&
                            [
                              'completed',
                              'booked',
                              'in-progress',
                              'pending-feedback'
                            ].includes(booking.status)
                          ) {
                            // Allow opening the material
                            auth.api.materials.openLessonDocument(
                              enqueueSnackbar,
                              lesson
                            );
                          } else {
                            // Display a message indicating that it's not allowed to open the material at the moment
                            enqueueSnackbar(
                              `You can open the material ${
                                auth?.role === 'tutor'
                                  ? '12 hours'
                                  : '20 minutes'
                              }  before to 24 hours after the lesson starts.`,
                              {
                                variant: 'warning'
                              }
                            );
                          }
                        } else {
                          // Display a message indicating that the material cannot be opened due to a mismatch
                          enqueueSnackbar(
                            'This material cannot be opened for the current lesson.',
                            {
                              variant: 'warning'
                            }
                          );
                        }
                      } else {
                        // Display a message indicating that the material cannot be opened
                        enqueueSnackbar(
                          'Cannot open material. Missing booking or lesson information.',
                          {
                            variant: 'error'
                          }
                        );
                      }
                    }}>
                    Open Material
                  </Button>
                )}
              </div>
            </>
          ) : (
            <>
              <div className={classes.detailsHeaderImgWrapper}>
                <Skeleton
                  className={classes.detailsHeaderImg}
                  variant="circle"
                />
              </div>
              <div className={classes.detailsHeaderInfo}>
                <Typography variant="h5">
                  <Skeleton width="60%" />
                </Typography>
                <Typography>
                  <Skeleton width="80%" />
                </Typography>
                <Typography>
                  <Skeleton width="70%" />
                </Typography>
                <Typography>
                  <Skeleton width="40%" />
                </Typography>
                {!hideOpenButton && (
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    fullWidth
                    className={classes.detailsHeaderButton}
                    disabled>
                    open material
                  </Button>
                )}
              </div>
            </>
          )}
        </div>
        <Divider className={classes.detailsHeaderDivider} />

        {!groupSession && (
          <>
            <Typography color="textSecondary">
              {introduction
                ? 'Start the lessons with introductions'
                : 'Skip introductions, get straight into the lesson'}
            </Typography>
            <Typography color="textSecondary" gutterBottom>
              {correctMistakes
                ? 'Actively correct my mistakes during the lesson'
                : 'Do not actively correct my mistakes'}
            </Typography>
          </>
        )}

        {notes && (
          <>
            <Divider className={classes.detailsHeaderDivider} />
            <Typography color="textSecondary">
              <b>Notes: </b>
              {notes}
            </Typography>
          </>
        )}
      </div>
    );
  }
}

export default withSnackbar(withAuth(withStyles(styles)(BookingInfo)));
