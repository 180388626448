import { PureComponent } from 'react';

// React router components
import { withRouter } from 'react-router-dom';

// Validation helper
import validate from 'validate.js';

// Snackbar provider
import { withSnackbar } from 'notistack';

import config from 'config';

// Firebase
import firebase from 'firebase/app';
import 'firebase/firebase-storage';

// Import material components
import {
  Avatar,
  Button,
  IconButton,
  TextField,
  Link,
  FormHelperText,
  Checkbox,
  Grow,
  CircularProgress,
  Typography,
  FormControl,
  Select,
  MenuItem,
  withStyles
} from '@material-ui/core';

// Date picker
import { DatePicker } from '@material-ui/pickers';

// Arrow icon
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

// DateTime for testing
// import { DateTime } from 'luxon';

// Autocomplete
import { Autocomplete } from '@material-ui/lab';

// To handle error messages
import { getErrorMessage, getInitials, countries, uuid } from 'helpers';

// Authentication provider
import { withAuth } from 'services/auth';

// Cleanup exports
import compose from 'recompose/compose';

// Upload component
import { Upload } from 'components';

// Component styles
import styles from './styles';

// Import the schema
import schema from './schema';

const capitalize = (input) => {
  if (typeof input === 'string' && input.length > 0)
    return input.charAt(0).toUpperCase() + input.slice(1);
};

class Application extends PureComponent {
  constructor(props) {
    super(props);

    // Setup a uuid for the application
    this.uuid = uuid();

    // Initialize the component's state
    this.state = {
      isValid: false,
      status: undefined,
      values: {
        gender: 'Male'
      },
      touched: {},
      errors: validate({}, schema)
    };

    // Bind the required functions
    this.handleProfileUpload = this.handleProfileUpload.bind(this);
    this.handleCVUpload = this.handleCVUpload.bind(this);
    this.handleDegreeUpload = this.handleDegreeUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.hasError = this.hasError.bind(this);

    // Initialize the ready flag
    this.ready = true;
  }

  componentWillUnmount() {
    this.ready = false;
  }

  handleChange(event) {
    // Grab the values from the state
    const { values, touched } = this.state;

    // Get the newly changed values
    const newValues = {
      ...values,
      [event.target.name]:
        event.target.type === 'checkbox'
          ? event.target.checked
          : event.target.value
    };

    // Validate the state's values
    const errors = validate(newValues, schema);

    // Update the state
    this.setState({
      values: newValues,
      touched: {
        ...touched,
        [event.target.name]: true
      },
      isValid: !errors,
      errors: errors || {}
    });
  }

  handleBack() {
    // Grab the history provider from the props
    const { history } = this.props;

    // Navigate back
    history.push('/signin');
  }

  async handleProfileUpload(image) {
    try {
      // Get the download URL of the image
      const imageURL = await firebase.storage().ref(image).getDownloadURL();

      // Update the state with the image
      if (this.ready) {
        this.handleChange({
          target: {
            name: 'image',
            value: imageURL
          }
        });
      }
    } catch (error) {
      // Get the enqueueSnackbar
      const { enqueueSnackbar } = this.props;

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  async handleCVUpload(cv) {
    try {
      // Get the download URL of the image
      const cvURL = await firebase.storage().ref(cv).getDownloadURL();

      // Update the state with the image
      if (this.ready) {
        this.handleChange({
          target: {
            name: 'cv',
            value: cvURL
          }
        });
      }
    } catch (error) {
      // Get the enqueueSnackbar
      const { enqueueSnackbar } = this.props;

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }
  async handleDegreeUpload(degree) {
    try {
      // Get the download URL of the image
      const degreeURL = await firebase.storage().ref(degree).getDownloadURL();

      // Update the state with the image
      if (this.ready) {
        this.handleChange({
          target: {
            name: 'degree',
            value: degreeURL
          }
        });
      }
    } catch (error) {
      // Get the enqueueSnackbar
      const { enqueueSnackbar } = this.props;

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  async handleSubmit(event) {
    // Prevent an actual form submit from occuring
    event.preventDefault();

    // Grab the auth provider from the props and state info
    const { isValid, values } = this.state;
    const { auth, enqueueSnackbar } = this.props;

    // Check whether the sign up form is valid
    if (isValid) {
      try {
        // Disable the form elements
        this.setState({ status: 'submitting' });

        // Submit the application
        await auth.api.applications.submitApplication({
          ...values,
          firstName: capitalize(values.firstName),
          lastName: capitalize(values.lastName),
          dob: values.dob.ts,
          nationality: values.nationality.label,
          country: values.country.label,
          currency: 'PHP'
        });

        // Update the state
        if (this.ready) {
          this.setState({ status: 'submitted' });
        }
      } catch (error) {
        // Log the error to the console
        console.error(error);

        // Re-enable the form elements
        if (this.ready) {
          this.setState({ status: undefined, isValid: false });

          // Show an error snackbar
          enqueueSnackbar(getErrorMessage(error), {
            variant: 'error'
          });
        }
      }
    }
  }

  hasError(field) {
    // Grab the touched and errors state info
    const { touched, errors } = this.state;
    return !!(touched[field] && errors[field]);
  }

  render() {
    // Grab the component's classes from its props
    const { classes } = this.props;

    // Grab the state info
    const { status, isValid, errors, values } = this.state;

    const isProdBranch = config.IS_PROD_BRANCH;

    const bucketName = isProdBranch
      ? 'eng-tutor-app.appspot.com'
      : 'eng-tutor-app-dev.appspot.com';

    if (status === 'submitted') {
      return (
        <div className={classes.rootHome}>
          <Grow in>
            <div className={classes.contentSubmitted}>
              <img
                alt=""
                src="images/connected_world.svg"
                className={classes.image}
              />
              <Typography variant="h2" className={classes.title}>
                Application Submitted
              </Typography>
              <Typography color="textSecondary" variant="h5">
                Thanks for submitting you application! Applications are
                typically reviewed and processed within 3-5 business days,
                however this is dependent on the volume of applications we
                receive.
                <br />
                <br />
                You will receive an email notifying you of your
                application&apos;s outcome once it has been processed.
              </Typography>
              <div className={classes.home}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.homeButton}
                  onClick={this.handleBack}>
                  awesome
                </Button>
              </div>
            </div>
          </Grow>
        </div>
      );
    }

    // Render the sign up page
    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <form className={classes.form} onSubmit={this.handleSubmit}>
            <div className={classes.formHeader}>
              <IconButton
                className={classes.backButton}
                onClick={this.handleBack}
                disabled={status === 'submitting'}>
                <ArrowBackIcon />
              </IconButton>
              <div>
                <Typography variant="h2">Teacher Application</Typography>
                <Typography className={classes.subtitle} gutterBottom>
                  Thanks for taking an interest in becoming a tutor for
                  e-English.
                </Typography>
              </div>
              <span className={classes.spacer} />
              <Grow in={status === 'submitting'}>
                <div>
                  <CircularProgress />
                </div>
              </Grow>
            </div>
            <Typography className={classes.subheading} gutterBottom>
              To complete this form you will need to have ready to upload a
              profile Photo, a CV in pdf that includes a copy of your tertiary
              education certificate and a short YouTube video. Read through the
              form for instructions about these requirements.
            </Typography>
            <div className={classes.formProfile}>
              <Avatar className={classes.avatar} src={values.image}>
                {getInitials(
                  (values.firstName || '') + (values.lastName || '')
                )}
              </Avatar>
            </div>
            <div style={{ marginTop: '1.5rem' }}>
              <Upload
                disabled={status === 'submitting'}
                label="Upload profile photo (<1MB)"
                path={`profiles/${this.uuid}`}
                onUploadSuccess={this.handleProfileUpload}
                maxSize={1024 * 1024}
                accept="image/*"
                customizedStyle={true}
              />
            </div>
            <div style={{ marginTop: '0.5rem' }}>
              <Upload
                disabled={status === 'submitting'}
                label="Upload CV (<1MB)"
                path={`cv/${this.uuid}`}
                onUploadSuccess={this.handleCVUpload}
                maxSize={1024 * 1024}
                accept="application/pdf"
              />
            </div>
            <div style={{ marginTop: '0.5rem' }}>
              <Upload
                disabled={status === 'submitting'}
                label="Upload Bachelor Degree Certificate (<1MB)"
                path={`degree/${this.uuid}`}
                onUploadSuccess={this.handleDegreeUpload}
                maxSize={1024 * 1024}
                accept="application/pdf"
                bachelorDegreeCertificateText={true}
              />
            </div>
            <Typography variant="h4" style={{ marginTop: '1rem' }}>
              Firstly, your details
            </Typography>
            <Typography className={classes.subtitle}>
              Only your name will be publicly visible.
            </Typography>

            <TextField
              className={classes.textField}
              error={this.hasError('firstName')}
              fullWidth
              helperText={
                this.hasError('firstName') ? errors.firstName[0] : null
              }
              label="First Name"
              name="firstName"
              onChange={this.handleChange}
              type="text"
              value={values.firstName || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <TextField
              className={classes.textField}
              error={this.hasError('lastName')}
              fullWidth
              helperText={this.hasError('lastName') ? errors.lastName[0] : null}
              label="Last Name"
              name="lastName"
              onChange={this.handleChange}
              type="text"
              value={values.lastName || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <DatePicker
              value={values.dob || null}
              format="dd/MM/yyyy"
              margin="normal"
              disableFuture
              views={['year', 'month', 'date']}
              label="Date Of Birth"
              onChange={(dateTime) => {
                this.handleChange({
                  target: {
                    name: 'dob',
                    value: dateTime
                  }
                });
              }}
              renderInput={(props) => (
                <TextField
                  fullWidth
                  variant="outlined"
                  className={classes.textField}
                  error={this.hasError('dob')}
                  helperText={this.hasError('dob') ? errors.dob[0] : null}
                  disabled={status === 'submitting'}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...props}
                />
              )}
            />
            <TextField
              className={classes.textField}
              error={this.hasError('email')}
              fullWidth
              helperText={this.hasError('email') ? errors.email[0] : null}
              label="Email"
              name="email"
              onChange={this.handleChange}
              type="text"
              value={values.email || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <TextField
              className={classes.textField}
              error={this.hasError('password')}
              fullWidth
              helperText={this.hasError('password') ? errors.password[0] : null}
              label="Password"
              name="password"
              onChange={this.handleChange}
              type="password"
              value={values.password || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <TextField
              className={classes.textField}
              error={this.hasError('confirmPassword')}
              fullWidth
              helperText={
                this.hasError('confirmPassword')
                  ? 'Please enter the same password as above'
                  : null
              }
              label="Confirm Password"
              name="confirmPassword"
              onChange={this.handleChange}
              type="password"
              value={values.confirmPassword || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <Typography variant="h4" className={classes.header}>
              Tell us a bit about yourself
            </Typography>
            <Typography className={classes.subtitle}>
              This information will be visible to students
            </Typography>
            <FormControl
              variant="outlined"
              className={classes.textField}
              fullWidth>
              <Select
                id="application-select-gender"
                value={values.gender || 'Male'}
                name="gender"
                onChange={this.handleChange}>
                <MenuItem value="Male">Male</MenuItem>
                {/* eslint-disable-next-line react/jsx-boolean-value */}
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </FormControl>
            <Autocomplete
              autoHighlight
              options={countries}
              getOptionLabel={(option) => option.label}
              className={classes.textField}
              onChange={(event, change) => {
                this.handleChange({
                  target: {
                    name: 'country',
                    value: change
                  }
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Country"
                  name="country"
                  variant="outlined"
                  error={this.hasError('country')}
                  helperText={
                    this.hasError('country') ? errors.country[0] : null
                  }
                  fullWidth
                />
              )}
            />
            <Autocomplete
              autoHighlight
              options={countries}
              getOptionLabel={(option) => option.label}
              className={classes.textField}
              onChange={(event, change) => {
                this.handleChange({
                  target: {
                    name: 'nationality',
                    value: change
                  }
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Nationality"
                  name="nationality"
                  variant="outlined"
                  error={this.hasError('nationality')}
                  helperText={
                    this.hasError('nationality') ? errors.nationality[0] : null
                  }
                  fullWidth
                />
              )}
            />
            <TextField
              className={classes.textField}
              error={this.hasError('school')}
              fullWidth
              helperText={this.hasError('school') ? errors.school[0] : null}
              label="Graduating School"
              name="school"
              onChange={this.handleChange}
              type="text"
              value={values.school || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <Typography className={classes.subheadingIntro}>
              Your
              <strong>
                &nbsp;Short Introduction is an opportunity to introduce yourself
                to students&nbsp;
              </strong>
              and your achievements, including the following:
            </Typography>
            <Typography component="div">
              <ul className={classes.bulletContainer}>
                <li className={classes.subtitle}>
                  Your Teaching experience and passion for teaching English
                </li>
                <li className={classes.subtitle}>
                  Your educational experience
                </li>
                <li
                  className={
                    classes.subtitle
                  }>{`Your work Study Area experience (eg Hospitality, Tourism etc)`}</li>
              </ul>
            </Typography>
            <TextField
              className={classes.textField}
              placeholder="Short introduction about why you want to be a tutor for e-English, and why you would be a good tutor."
              error={this.hasError('introduction')}
              fullWidth
              helperText={
                this.hasError('introduction') ? errors.introduction[0] : null
              }
              label="Short Introduction"
              name="introduction"
              onChange={this.handleChange}
              type="text"
              value={values.introduction || ''}
              variant="outlined"
              disabled={status === 'submitting'}
              multiline
              rows={4}
            />
            <Typography className={classes.subheadingIntro}>
              Develop a 30 sec (maximum)
              <strong>&nbsp;Introduction Video&nbsp;</strong>
              based on your Short Introduction.The video will need to have
              <strong>&nbsp;Unlisted or Public visibility.&nbsp;</strong>
            </Typography>
            <TextField
              className={classes.textField}
              error={this.hasError('video')}
              fullWidth
              helperText={this.hasError('video') ? errors.video[0] : null}
              label="YouTube Video URL"
              name="video"
              onChange={this.handleChange}
              type="text"
              value={values.video || ''}
              variant="outlined"
              disabled={status === 'submitting'}
            />
            <Typography className={classes.intructions}>
              {/* eslint-disable */}
              Instructions for{' '}
              <Link
                href="https://support.google.com/youtube/answer/57407"
                target="_blank">
                uploading to YouTube
              </Link>{' '}
              and{' '}
              <Link
                href="https://gist.github.com/jakebellacera/d81bbf12b99448188f183141e6696817"
                target="_blank">
                retrieving your video ID
              </Link>
              {/* eslint-enable */}
            </Typography>
            <div className={classes.policy}>
              <Checkbox
                checked={values.tutorPolicy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="tutorPolicy"
                onChange={this.handleChange}
                disabled={status === 'submitting'}
              />
              <Typography
                className={classes.policyText}
                color="textSecondary"
                variant="body1">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                I have read the{' '}
              </Typography>
              <Typography
                onClick={() =>
                  window
                    .open(
                      `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/tutorapplicationterms%2FTutor%20Application%20Terms.pdf?alt=media&token=3281a587-6f6a-4b24-9e54-c0de2619f1ef`,
                      '_blank'
                    )
                    .focus()
                }
                className="policy-text"
                variant="h6"
                style={{ marginLeft: 5 }}>
                Tutor Application Terms
              </Typography>
            </div>
            {this.hasError('tutorPolicy') && (
              <FormHelperText error>{errors.tutorPolicy[0]}</FormHelperText>
            )}
            <div className={classes.policyBottom}>
              <Checkbox
                checked={values.policy || false}
                className={classes.policyCheckbox}
                color="primary"
                name="policy"
                onChange={this.handleChange}
                disabled={status === 'submitting'}
              />
              <Typography
                className={classes.policyText}
                color="textSecondary"
                variant="body1">
                {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                I have read the e-English app
              </Typography>
              <Typography
                onClick={() =>
                  window
                    .open('https://e-english.com.au/terms-of-use/', '_blank')
                    .focus()
                }
                className="policy-text"
                variant="h6"
                style={{ marginLeft: 5 }}>
                Terms and Conditions
              </Typography>
            </div>
            {this.hasError('policy') && (
              <FormHelperText error>{errors.policy[0]}</FormHelperText>
            )}
            <div className={classes.applyErrors}>
              {Object.entries(errors)
                .slice(0, 3)
                .map(([key, error]) => (
                  <Typography
                    className={classes.applyErrorText}
                    variant="subtitle2"
                    key={key}>
                    {error}
                  </Typography>
                ))}
            </div>
            <Button
              className={classes.applyButton}
              color="primary"
              disabled={!isValid || status === 'submitting'}
              fullWidth
              size="large"
              type="submit"
              variant="contained">
              Apply Now
            </Button>
          </form>
        </div>
      </div>
    );
  }
}

export default compose(
  withAuth,
  withRouter,
  withSnackbar,
  withStyles(styles)
)(Application);
