import {
  useEffect,
  MouseEventHandler,
  useContext,
  useState,
  useCallback,
  useRef
} from 'react';

// Twilio imports
// import { LocalVideoTrack, LocalParticipant } from 'twilio-video';

// Authentication & call contexts
import { AuthContext } from 'services/auth';
import { CallContext } from 'services/call';

// Material UI components / helpers
import {
  Button,
  ButtonGroup,
  Popover,
  Tooltip,
  Fab,
  Badge
} from '@material-ui/core';

// Material UI styles
import { makeStyles } from '@material-ui/core';

// Material UI Icons
import {
  MicOffRounded,
  MicRounded,
  VideocamOffRounded,
  VideocamRounded,
  CallEndRounded,
  // BugReportRounded,
  // AssignmentRounded,
  ExpandLessRounded,
  ChatRounded,
  ScreenShareRounded,
  CancelRounded
} from '@mui/icons-material';

// Firebase & firestore imports
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

// Chat component & message type
import { Chat } from 'components';
import { ChatMessage } from 'services/auth/api/chat';

// Media devices select component
import MediaSelect from './components/MediaSelect';

// Error message helper
import { getErrorMessage } from 'helpers';

// Component styles
import styles from './styles';
import useWindowSize from 'hooks/useWindowSize';
const useStyles = makeStyles(styles);

interface Props {
  onDisconnect?: () => void;
  bookingId?: string;
}

export function Controls(props: Props): JSX.Element {
  const { onDisconnect, bookingId } = props;
  const auth = useContext(AuthContext);
  const call = useContext(CallContext);
  const classes = useStyles();
  const [width] = useWindowSize();

  // Chat state
  const [chatAnchor, setChatAnchor] = useState<HTMLButtonElement | null>(null);
  const [chatMessages, setChatMessages] = useState<ChatMessage[] | null>(null);
  const [chatError, setChatError] = useState<string | null>(null);
  const [chatRetry, setChatRetry] = useState<boolean>(false);
  const enableChatBadge = useRef<boolean>(true);
  const lastTimeUpdate = useRef<number>(Date.now());

  const [chatCount, setChatCount] = useState<number>(0);
  // Media devices state
  const [micSelectAnchor, setMicSelectAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [camSelectAnchor, setCamSelectAnchor] =
    useState<HTMLButtonElement | null>(null);
  const [mediaDevices, setMediaDevices] = useState<MediaDeviceInfo[] | null>(
    null
  );

  // Effect hook for retrieving media devices
  useEffect(() => {
    async function retrieveMediaDevices() {
      try {
        setMediaDevices(await navigator.mediaDevices.enumerateDevices());
      } catch (error) {
        console.log(error);
      }
    }

    // Trigger the helper function
    retrieveMediaDevices();
  }, []);

  // Function to play chat message sound notification
  const playChatMessageSound = () => {
    const audio = new Audio(
      'https://codeskulptor-demos.commondatastorage.googleapis.com/pang/pop.mp3'
    ); // Adjust path as per your project structure
    audio.play();
  };

  // Effect hook for listening to the chat
  useEffect(() => {
    if (bookingId) {
      // Start listening to the chat document
      const chatUnlisten = firebase
        .firestore()
        .collection('chat')
        .doc(bookingId)
        .collection('items')
        .orderBy('when', 'asc')
        .onSnapshot(
          (snap) => {
            const msg = snap.docs.map((doc) => ({
              ...(doc.data() as ChatMessage),
              id: doc.id
            }));

            // Count & set new message badge
            const msgs = msg.filter((doc) => {
              return (
                !chatMessages?.includes(doc) &&
                auth?.user?.uid != doc.uid &&
                doc.when >= lastTimeUpdate.current
              );
            }).length;

            if (enableChatBadge.current) setChatCount(msgs);

            setChatMessages(msg);

            if (msg.length > 1) {
              playChatMessageSound(); // Function to play sound notification
            }
          },
          (error) => {
            setChatError(getErrorMessage(error));
            console.log(error);
          }
        );

      // Stop listening to the firestore collection when unmounting
      return () => chatUnlisten();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatRetry]);

  // Chat message send handler
  const handleChatSend = useCallback(
    async (message: string) => {
      if (!bookingId) return;
      try {
        // Pre-emptively update the chat state
        setChatMessages([
          ...(chatMessages || []),
          {
            id: `${Date.now()}-temp`,
            uid: auth?.user?.uid || 'no-id',
            name: auth?.user?.displayName || 'no-name',
            content: message,
            when: Date.now(),
            unsent: true
          }
        ]);
        console.log(bookingId, message);
        // Make the request
        await auth?.api.chat.send(bookingId, message);
      } catch (error) {
        // Update the chat error state
        setChatError(getErrorMessage(error));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chatMessages]
  );

  // Chat retry handler
  const handleChatRetry = useCallback(() => {
    // Clear the current chat messages & error
    setChatMessages(null);
    setChatError(null);

    // Retry the chat connection
    setChatRetry(!chatRetry);
  }, [chatRetry]);

  // Mute toggle handler
  const handleToggleMute = useCallback(() => {
    if (call.toggleMute) call.toggleMute();
  }, [call]);

  // Camera toggle handler
  const handleToggleCamera = useCallback(() => {
    if (call.toggleCamera) call.toggleCamera();
  }, [call]);

  // Disconnect handler
  const handleDisconnect = useCallback(() => {
    if (call.leave) call.leave();
    if (onDisconnect) onDisconnect();
  }, [call, onDisconnect]);

  // Toggle chat popup handler
  const handleChatPopup: MouseEventHandler<HTMLButtonElement> = (ev): void => {
    /** Nulify Chat Badge */
    setChatCount(0);
    enableChatBadge.current = false;
    setChatAnchor(ev.target as HTMLButtonElement);
  };

  // Show mic select handler
  const handleMicSelectPopup: MouseEventHandler<HTMLButtonElement> = (
    ev
  ): void => {
    setMicSelectAnchor(ev.target as HTMLButtonElement);
  };

  // Show camera select handler
  const handleCamSelectPopup: MouseEventHandler<HTMLButtonElement> = (
    ev
  ): void => {
    setCamSelectAnchor(ev.target as HTMLButtonElement);
  };

  // const handleTest = useCallback(() => {
  //   if (call.localParticipant) {
  //     const videoTracks = Array.from(call.localParticipant.videoTracks.values()).map((trackPub) => trackPub.track);
  //     call.localParticipant.unpublishTracks(videoTracks);
  //   }
  // }, [call.localParticipant]);

  // Screen share toggle handler
  const handleScreenShare = useCallback(async () => {
    if (call && call.screenSharing) {
      if (call.stopScreenSharing) {
        await call.stopScreenSharing();
      } else {
        console.error('stopScreenSharing function is not defined.');
      }
    } else if (call && !call.screenSharing) {
      if (call.startScreenSharing) {
        await call.startScreenSharing();
      } else {
        console.error('startScreenSharing function is not defined.');
      }
    }
  }, [call]);

  return (
    <>
      {mediaDevices && (
        <>
          <Popover
            id={micSelectAnchor ? 'mic-select-popover' : undefined}
            open={Boolean(micSelectAnchor)}
            anchorEl={micSelectAnchor}
            onClose={() => {
              setMicSelectAnchor(null);
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}>
            <MediaSelect kind="audioinput" mediaDevices={mediaDevices} />
          </Popover>
          <Popover
            id={camSelectAnchor ? 'camera-select-popover' : undefined}
            open={Boolean(camSelectAnchor)}
            anchorEl={camSelectAnchor}
            onClose={() => {
              setCamSelectAnchor(null);
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}>
            <MediaSelect kind="videoinput" mediaDevices={mediaDevices} />
          </Popover>
        </>
      )}
      <Popover
        id={chatAnchor ? 'chat-popover' : undefined}
        open={Boolean(chatAnchor)}
        anchorEl={chatAnchor}
        onClose={() => {
          // Update last message time
          lastTimeUpdate.current = Date.now();
          setChatAnchor(null);
          enableChatBadge.current = true;
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}>
        <Chat
          messages={chatMessages}
          error={chatError}
          uid={auth?.user?.uid || 'no-id'}
          onSend={handleChatSend}
          onRetry={handleChatRetry}
        />
      </Popover>
      {/* <div className={classes.root}> */}
      <div
        className={`${classes.root} ${
          width < 500 ? classes.rootResponsive : ''
        }`}>
        <Tooltip title="Toggle Chat">
          <Fab
            size="medium"
            color="secondary"
            className={classes.btnControl}
            onClick={handleChatPopup}
            aria-label="toggle chat"
            disabled={!bookingId}>
            <Badge color="error" badgeContent={chatCount}>
              <ChatRounded />
            </Badge>
          </Fab>
        </Tooltip>
        <ButtonGroup className={classes.btnControl}>
          <Button
            style={{
              minWidth: width > 900 ? 148 : width < 370 ? 50 : 'auto',
              height: '48px'
            }}
            color="primary"
            variant="contained"
            startIcon={call.muted ? <MicOffRounded /> : <MicRounded />}
            onClick={handleToggleMute}
            aria-label="toggle microphone">
            {width > 900 ? (!call.muted ? 'Mic on' : 'Mic off') : ''}
          </Button>
          <Button
            style={{
              minWidth: width < 370 ? 20 : 'auto',
              height: '48px'
            }}
            variant="contained"
            color="primary"
            size="small"
            disabled={!mediaDevices}
            onClick={handleMicSelectPopup}>
            <ExpandLessRounded />
          </Button>
        </ButtonGroup>
        <ButtonGroup className={classes.btnControl}>
          <Button
            style={{
              minWidth: width > 900 ? 148 : width < 370 ? 50 : 'auto',
              height: '48px'
            }}
            color="primary"
            variant="contained"
            startIcon={
              call.camera ? <VideocamRounded /> : <VideocamOffRounded />
            }
            onClick={handleToggleCamera}
            aria-label="toggle camera">
            {width > 900 ? (call.camera ? 'Camera on' : 'Camera off') : ''}
          </Button>
          <Button
            style={{
              minWidth: width < 370 ? 20 : 'auto',
              height: '48px'
            }}
            variant="contained"
            color="primary"
            size="small"
            disabled={!mediaDevices}
            onClick={handleCamSelectPopup}>
            <ExpandLessRounded />
          </Button>
        </ButtonGroup>
        {auth?.role == 'tutor' && (
          <Tooltip title={call.screenSharing ? 'Stop Share' : 'Screen Share'}>
            <Fab
              size="medium"
              color="secondary"
              className={classes.btnControl}
              onClick={handleScreenShare}
              aria-label="screen share"
              disabled={!bookingId}>
              {call.screenSharing ? <CancelRounded /> : <ScreenShareRounded />}
            </Fab>
          </Tooltip>
        )}

        <Tooltip title="Leave Call">
          <Fab
            size="medium"
            className={`${classes.hangUpBtn} ${classes.btnControl}`}
            onClick={handleDisconnect}
            disabled={!call.connected}
            aria-label="leave call">
            <CallEndRounded />
          </Fab>
        </Tooltip>
      </div>
    </>
  );
}
