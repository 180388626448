import { Component, createRef } from 'react';

// Material UI imports
import {
  Popover,
  Grid,
  Button,
  withStyles,
  Typography,
  CircularProgress
} from '@material-ui/core';

// Material UI icons
import {
  CallRounded as JoinIcon,
  AssignmentRounded as DetailsIcon
  // PublishOutlined
} from '@mui/icons-material';

// DateTime helpers
import { DateTime } from 'luxon';

// To handle error messages
import { getErrorMessage } from 'helpers';

// Snackbar provider
import { withSnackbar } from 'notistack';

// Router provider
import { withRouter } from 'react-router-dom';

// Portlet items
import {
  Portlet,
  PortletHeader,
  PortletLabelAvatar,
  PortletContent
} from 'components';

// Booking info component
import BookingInfo from 'components/BookingInfo';

// To clean up exports
import compose from 'recompose/compose';

// Style
import styles from './styles';

// import { SubmitFeedbackDialog } from 'views/shared/Bookings/components';

class JoinCard extends Component {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      currentBooking: null,
      bookings: [],
      groupSessions: [],
      callJoining: false,
      callToken: null,
      callWindowHandle: null,
      popoverAnchor: null,
      initialToken: null,
      checkBeforeunload: false
    };

    this.cancelConfirmDialog = createRef();
    // this.submitFeedbackDialog = createRef();

    // Bind the required functions
    this.refresh = this.refresh.bind(this);
    this.onUnload = this.onUnload.bind(this);
    this.onSnapData = this.onSnapData.bind(this);
    // this.onSnapDataGroupSessions = this.onSnapDataGroupSessions.bind(this);
    this.onSnapError = this.onSnapError.bind(this);
    this.joinBooking = this.joinBooking.bind(this);
    this.gotoBookings = this.gotoBookings.bind(this);
    this.retryHook = this.retryHook.bind(this);
    // this.onSubmitFeedback = this.onSubmitFeedback.bind(this);

    // Set the ready flag to true
    this.ready = true;
    this.onBeforeUnloadFired = false;
  }

  async componentDidMount() {
    const { auth } = this.props;

    // Retrive the current user's bookings from the API.
    this.bookingsHook = auth.api.bookings.hook(
      'bookingsMyLiveBookings',
      this.onSnapData,
      this.onSnapError,
      'JoinCard'
    );
    // this.groupSessionsHook = auth.api.groupSessions.hook(
    //   'groupsessionsMyLiveBookings',
    //   this.onSnapDataGroupSessions,
    //   this.onSnapError,
    //   'UpcomingBookingsView'
    // );
    // Create an update interval every second
    this.refreshInterval = setInterval(this.refresh, 1000);

    window.addEventListener('beforeunload', this.onUnload);
    window.addEventListener('pagehide', this.onUnload);
  }

  componentWillUnmount() {
    // If the unsubscribe function is valid, unsubscribe
    if (this.bookingsHook) this.bookingsHook();
    if (this.refreshInterval) clearInterval(this.refreshInterval);

    // Set the ready flag to false
    this.ready = false;

    window.removeEventListener('beforeunload', this.onUnload);
    window.removeEventListener('pagehide', this.onUnload);
  }

  onUnload() {
    const { callWindowHandle } = this.state;
    if (callWindowHandle) callWindowHandle.close();
  }

  onSnapData(snap) {
    // Update the component's state with the bookings.
    if (this.ready) {
      this.setState({
        bookings: snap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }))
      });
    }
  }
  // onSnapDataGroupSessions(snap) {
  //   const { auth } = this.props;
  //   if (this.ready) {
  //     const currentUserId = auth.user.uid;
  //     const bookingsArray = [];
  //     // current time + 10 minutes
  //     console.log('currentTime', Date.now() + 25 * 60 * 1000);
  //     snap.docs.forEach((doc) => {
  //       const id = doc.id;
  //       const sessionData = {
  //         id,
  //         ...doc.data()
  //       };

  //       if (sessionData.bookings) {
  //         sessionData.bookings
  //           .filter((booking) => {
  //             // Check if the current user is a student or the tutor in this booking
  //             const isStudent =
  //               booking.studentIds &&
  //               booking.studentIds.includes(currentUserId);
  //             const isTutor =
  //               booking.tutor && booking.tutor.uid === currentUserId;

  //             const isUpcoming =
  //               booking.status === 'booked' ||
  //               booking.status === 'in-progress' ||
  //               booking.status === 'open';

  //             return (isStudent || isTutor) && isUpcoming;
  //           })
  //           .forEach((filteredBooking) => {
  //             bookingsArray.push({
  //               ...sessionData,
  //               bookingId: filteredBooking.bookingId,
  //               when: filteredBooking.when,
  //               bookings: [filteredBooking],
  //               status: filteredBooking.status,
  //               profile: {
  //                 ...filteredBooking.profile,
  //                 student:
  //                   filteredBooking?.students?.length &&
  //                   filteredBooking.students[0],
  //                 tutor: filteredBooking.tutor
  //               },
  //               studentCount: filteredBooking.students?.length - 1
  //             });
  //           });
  //       }
  //     });
  //     console.log('sessionData', bookingsArray);

  //     // Update the state with the flattened bookings array
  //     this.setState({
  //       groupSessions: bookingsArray.sort((a, b) => a.when - b.when)
  //     });
  //   }
  // }

  onSnapError(error) {
    // Grab the snackbar function
    const { enqueueSnackbar } = this.props;

    // Show an error snackbar
    enqueueSnackbar(`${getErrorMessage(error)} (try reloading the page)`, {
      variant: 'error'
    });

    // Log the error to the console.
    console.error(error);
  }

  gotoBookings() {
    // Grab the history provider from the props
    const { history, auth } = this.props;

    // Navigate to the bookings page
    history.push(`/${auth.role}/bookings`);
  }

  refresh() {
    const { bookings, groupSessions } = this.state;

    // Helper function to find a valid booking or group session
    const findValidBooking = (items) => {
      for (const item of items) {
        const timeDiff = Date.now() - item.when;

        // Check if the item is within the allowed time range and not in invalid statuses
        if (
          // timeDiff >= -3600000 && // within 1 hour before
          timeDiff >= -600000 && // Within 10 minutes before
          timeDiff <= 1200000 && // Within 20 minutes after
          ![
            'completed',
            'pending',
            'student-cancelled',
            'tutor-cancelled'
          ].includes(item.status)
        ) {
          return item; // Return the first valid booking or group session
        }
      }
      return null; // Return null if no valid item is found
    };

    // Find the current booking or group session
    const validBooking = findValidBooking(bookings);

    if (validBooking) {
      this.setState({ currentBooking: validBooking });
      return;
    }

    const validGroupSession = findValidBooking(groupSessions);
    console.log('validBooking1', validGroupSession);

    if (validGroupSession) {
      validGroupSession.type = 'group';
      this.setState({ currentBooking: validGroupSession });
      return;
    }

    // If no valid booking or group session, clear the state
    this.setState({ currentBooking: null, callToken: null });
  }

  retryHook() {
    // Reset the state
    this.setState({
      currentBooking: null
    });

    // Unsubscribe from the bookings hook
    if (this.bookingsHook) this.bookingsHook();

    // Call componentDidMount to call the API again.
    this.componentDidMount();
  }

  async joinBooking(callWindowHandle) {
    const { auth, enqueueSnackbar } = this.props;
    const { currentBooking, callToken } = this.state;

    try {
      const token =
        callToken ||
        (currentBooking?.type === 'group'
          ? await auth.api.groupSessions.joinGroupSession(
              currentBooking.id,
              currentBooking.bookingId
            )
          : await auth.api.bookings.joinBooking(
              currentBooking.id,
              currentBooking.tutor
            )
        ).token;

      //Redirect to the original url to join room
      let redirection = `/call?token=${token}&room=${currentBooking.tutor}-${currentBooking.id}`;

      if (currentBooking.type === 'group') {
        redirection = `/call?token=${token}&room=${currentBooking.tutor}-${currentBooking.bookingId}&group=true`;
      }

      callWindowHandle.location = redirection;

      let refreshIntervalId = setInterval(() => {
        if (callWindowHandle.closed) {
          // Update the commencing state
          this.setState({
            callToken: token,
            callJoining: false,
            callWindowHandle
          });

          // Update the call window handle state
          this.setState({
            callWindowHandle: null
          });

          // Clear localstorage
          localStorage.removeItem(`call-${currentBooking.id}`);

          clearInterval(refreshIntervalId);
        }
      }, 1000);
    } catch (error) {
      // Log the error to the console.
      console.error(error);

      // Update the state
      this.setState({
        callJoining: false
      });

      // Show an error snackbar
      enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
    }
  }

  // async onSubmitFeedback(booking, values) {
  //   // Retrive the enqueueSnackbar function and auth provider from props
  //   const { auth, enqueueSnackbar } = this.props;

  //   try {
  //     enqueueSnackbar(`Submitting feedback...`);

  //     // Delete the material
  //     await auth.api.bookings.submitFeedback(booking.id, values);

  //     // Update the state
  //     this.setState({
  //       bookings: this.state.bookings.map((stateBooking) =>
  //         booking.id === stateBooking.id
  //           ? {
  //               ...stateBooking,
  //               isFeedbackSubmitted: true,
  //               feedback: values
  //             }
  //           : stateBooking
  //       )
  //     });

  //     // Notify the user of the deletion
  //     enqueueSnackbar(`Feedback submitted!`, { variant: 'success' });
  //   } catch (error) {
  //     // Log the error to the console.
  //     console.error(error);

  //     // Show an error snackbar
  //     enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
  //   }
  // }

  render() {
    const { classes, auth } = this.props;

    const { currentBooking, callJoining, callWindowHandle, popoverAnchor } =
      this.state;
    // Determine the opposite role
    const roleSwitch = {
      student: 'tutor',
      tutor: 'student'
    }[auth.role];

    // Make sure the booking is not null, and has been booked by a student
    if (!currentBooking) return null;

    return (
      <>
        {/* {auth.role === 'tutor' && (
          <SubmitFeedbackDialog
            ref={this.submitFeedbackDialog}
            onConfirm={this.onSubmitFeedback}
          />
        )} */}
        <Popover
          id={popoverAnchor ? 'simple-popover' : undefined}
          open={Boolean(popoverAnchor)}
          anchorEl={popoverAnchor}
          onClose={() => {
            this.setState({ popoverAnchor: null });
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}>
          <BookingInfo info={currentBooking.info} booking={currentBooking} />
        </Popover>

        <div className={classes.root}>
          <Portlet className={classes.portlet}>
            <PortletHeader>
              {currentBooking.profile[roleSwitch] && (
                <PortletLabelAvatar
                  avatar={currentBooking.profile[roleSwitch].image}
                  name={currentBooking.profile[roleSwitch].name}
                  title={
                    currentBooking?.type && auth.role === 'tutor'
                      ? `Group Session Booking with ${currentBooking.profile[roleSwitch].name} and ${currentBooking?.studentCount} others`
                      : `Booking with ${currentBooking.profile[roleSwitch].name}`
                  }
                  subtitle={DateTime.fromMillis(
                    currentBooking.when
                  ).toRelative()}
                />
              )}
            </PortletHeader>
            <PortletContent>
              <Grid container spacing={1} justifyContent="center">
                <Grid item>
                  <div className={classes.waiting}>
                    <Button
                      className={classes.bookingsBtn}
                      variant="outlined"
                      onClick={(ev) => {
                        this.setState({ popoverAnchor: ev.target });
                      }}
                      color="primary"
                      startIcon={<DetailsIcon />}>
                      view details
                    </Button>
                    {(() => {
                      if (auth.role === 'student') {
                        if (currentBooking.status === 'in-progress') {
                          return (
                            <Button
                              disabled={
                                Boolean(callWindowHandle) || callJoining
                              }
                              variant="contained"
                              onClick={() => {
                                const { enqueueSnackbar } = this.props;
                                const { currentBooking } = this.state;

                                // Ensure the booking is valid
                                if (!currentBooking) {
                                  // Show an error snackbar
                                  enqueueSnackbar(
                                    'Cannot commence an empty booking!',
                                    {
                                      variant: 'error'
                                    }
                                  );
                                  return;
                                }
                                this.setState({ callJoining: true });
                                const bookingId =
                                  currentBooking.type === 'group'
                                    ? currentBooking.bookingId
                                    : currentBooking.id;

                                localStorage.setItem(
                                  `call-${bookingId}`,
                                  JSON.stringify({
                                    ...currentBooking,
                                    id: currentBooking.bookingId
                                  })
                                );

                                //open an empty window to avoid from blocking popup from Safari
                                const callWindowHandle = window.open(
                                  '',
                                  '_blank',
                                  'location'
                                );
                                this.joinBooking(callWindowHandle);
                              }}
                              color="primary"
                              startIcon={<JoinIcon />}>
                              {callJoining && (
                                <CircularProgress
                                  className={classes.callLoader}
                                  size={24}
                                />
                              )}
                              join lesson
                            </Button>
                          );
                        } else if (currentBooking.status === 'tutor-absent') {
                          return (
                            <Typography
                              className={classes.waitingText}
                              variant="subtitle1">
                              Sorry, it looks like we can&apos;t get a hold of
                              your tutor. Your lesson token has been refunded.
                            </Typography>
                          );
                        } else {
                          return (
                            <>
                              <div className={classes.waitingLoader}>
                                <CircularProgress size={30} />
                              </div>
                              <Typography
                                className={classes.waitingText}
                                variant="subtitle1">
                                Waiting for your tutor to commence the lesson
                              </Typography>
                            </>
                          );
                        }
                      } else if (auth.role === 'tutor') {
                        if (
                          currentBooking.status === 'booked' ||
                          currentBooking.status === 'open'
                        ) {
                          if (Date.now() > currentBooking.when) {
                            return (
                              <Typography
                                className={classes.waitingText}
                                variant="subtitle1">
                                Commencement deadline missed, student has been
                                refunded.
                              </Typography>
                            );
                          }

                          return (
                            <>
                              <Button
                                disabled={
                                  Boolean(callWindowHandle) || callJoining
                                }
                                className={classes.bookingsBtn}
                                variant="contained"
                                onClick={() => {
                                  const { enqueueSnackbar } = this.props;
                                  const { currentBooking } = this.state;

                                  // Ensure the booking is valid
                                  if (!currentBooking) {
                                    // Show an error snackbar
                                    enqueueSnackbar(
                                      'Cannot commence an empty booking!',
                                      {
                                        variant: 'error'
                                      }
                                    );
                                    return;
                                  }
                                  this.setState({ callJoining: true });

                                  const bookingId =
                                    currentBooking.type === 'group'
                                      ? currentBooking.bookingId
                                      : currentBooking.id;

                                  localStorage.setItem(
                                    `call-${bookingId}`,
                                    JSON.stringify({
                                      ...currentBooking,
                                      id: currentBooking.bookingId
                                    })
                                  );

                                  //open an empty window to avoid from blocking popup from Safari
                                  const callWindowHandle = window.open(
                                    '',
                                    '_blank',
                                    'location'
                                  );
                                  this.joinBooking(callWindowHandle);
                                }}
                                color="primary"
                                startIcon={<JoinIcon />}>
                                {callJoining && (
                                  <CircularProgress
                                    className={classes.callLoader}
                                    size={24}
                                  />
                                )}
                                Commence Lesson
                              </Button>
                              {/* {isProgressBooking &&
                                isProgressBooking.status !== 'completed' && (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<PublishOutlined />}
                                    onClick={() => {
                                      if (auth.role === 'tutor') {
                                        // Open the feedback dialog for the specific booking
                                        this.submitFeedbackDialog.current.open(
                                          isProgressBooking
                                        );
                                      }
                                    }}
                                    aria-label="submit feedback">
                                    SUBMIT FEEDBACK
                                  </Button>
                                )} */}
                            </>
                          );
                        } else if (currentBooking.status === 'in-progress') {
                          // Calculate the relative time remaining until the lesson ends
                          // const relativeTime = DateTime.fromMillis(
                          //   currentBooking.when + 1500000
                          // ).toRelative();
                          return (
                            <>
                              <Button
                                disabled={
                                  Boolean(callWindowHandle) || callJoining
                                }
                                className={classes.bookingsBtn}
                                variant="contained"
                                onClick={() => {
                                  const { enqueueSnackbar } = this.props;
                                  const { currentBooking } = this.state;

                                  // Ensure the booking is valid
                                  if (!currentBooking) {
                                    // Show an error snackbar
                                    enqueueSnackbar(
                                      'Cannot commence an empty booking!',
                                      {
                                        variant: 'error'
                                      }
                                    );
                                    return;
                                  }
                                  this.setState({ callJoining: true });

                                  const bookingId =
                                    currentBooking.type === 'group'
                                      ? currentBooking.bookingId
                                      : currentBooking.id;

                                  localStorage.setItem(
                                    `call-${bookingId}`,
                                    JSON.stringify({
                                      ...currentBooking,
                                      id: currentBooking.bookingId
                                    })
                                  );

                                  //open an empty window to avoid from blocking popup from Safari
                                  const callWindowHandle = window.open(
                                    '',
                                    '_blank',
                                    'location'
                                  );
                                  this.joinBooking(callWindowHandle);
                                }}
                                color="primary"
                                startIcon={<JoinIcon />}>
                                {callJoining && (
                                  <CircularProgress
                                    className={classes.callLoader}
                                    size={24}
                                  />
                                )}
                                Join Lesson
                              </Button>
                              {/* {relativeTime.includes('in') &&
                                relativeTime.includes('minutes') &&
                                parseInt(relativeTime.split(' ')[1]) <= 5 && (
                                  <Button
                                    color="primary"
                                    variant="contained"
                                    startIcon={<PublishOutlined />}
                                    onClick={() => {
                                      if (auth.role === 'tutor')
                                        this.submitFeedbackDialog.current.open(
                                          currentBooking
                                        );
                                    }}
                                    aria-label="submit feedback">
                                    SUBMIT FEEDBACK
                                  </Button>
                                )} */}
                            </>
                          );
                        } else if (currentBooking.status === 'tutor-absent') {
                          return (
                            <>
                              <Typography
                                className={classes.waitingText}
                                variant="subtitle1">
                                Commencement deadline missed, student has been
                                refunded.
                              </Typography>
                            </>
                          );
                        }
                      }
                    })()}
                  </div>
                </Grid>
              </Grid>
            </PortletContent>
          </Portlet>
        </div>
      </>
    );
  }
}

export default compose(withRouter, withSnackbar, withStyles(styles))(JoinCard);
