import { useEffect, useState } from 'react';

// Material ui components
import { Typography } from '@material-ui/core';

// Luxon
import { DateTime } from 'luxon';

// Component styles
import useStyles from './styles';

interface CountdownProps {
  when: number;
  onFinished?: () => void;
  handleChange?: () => void;
}

export const Countdown = (props: CountdownProps): JSX.Element => {
  const [, setUpdate] = useState<number>(0);
  const classes = useStyles();
  const { when, onFinished, handleChange } = props;

  // Start the countdown
  useEffect(() => {
    const interval = setInterval(() => {
      const update = Date.now();

      // If the lesson timer has ended, clear the interval
      if (update >= when + 3000000) {
        clearInterval(interval);

        // Trigger the function to leave the call
        if (onFinished) onFinished();
      } else {
        setUpdate(update);
      }
    }, 1000);

    // Remove the updater on unmount
    () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Typography
      onClick={() => handleChange}
      className={classes.countdown}
      variant="subtitle1"
      color="textSecondary">
      {when
        ? `Lesson ends ${DateTime.fromMillis(when + 3000000).toRelative()}`
        : 'N/A'}
    </Typography>
  );
};
