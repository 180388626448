export default (theme) => ({
  root: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(2),
    display: 'flex',
    flexGrow: 1
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2.5),
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'center'
    }
  },
  actionsDivider: {
    marginBottom: theme.spacing(4)
  },

  reminderAlert: {
    marginBottom: theme.spacing(1.5)
  },
  dateControls: {
    padding: theme.spacing(2.5),
    marginBottom: theme.spacing(4)
  },
  avatar: {
    width: 100,
    height: 100,
    transition: 'opacity 200ms cubic-bezier(0, 1, 1, 1)',
    '&:hover': {
      opacity: 0.7,
      cursor: 'pointer'
    }
  },
  avatarWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.only('xs')]: {
      paddingBottom: 0
    }
  },
  footer: {
    display: 'flex',
    justifyContent: 'center'
  },

  rebookBad: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  cancelledBook: {
    backgroundColor: theme.palette.warning.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    },
    '&:hover': {
      backgroundColor: theme.palette.warning.main
    }
  },
  rebookNormal: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    [theme.breakpoints.up('xs')]: {
      marginRight: theme.spacing(1)
    }
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.only('xs')]: {
      textAlign: 'center'
    }
  },

  gutterRight: {
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1)
    }
  },
  hideOnSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none'
    }
  },

  inelegible: {
    marginTop: theme.spacing(0.25),
    marginBottom: theme.spacing(0.25)
  }
});
