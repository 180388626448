import { PureComponent } from 'react';

// Material UI imports
import { FormHelperText, TextField, Typography } from '@material-ui/core';

// Responsive dialog
import ResponsiveDialog from 'components/ResponsiveDialog';

class ConfirmDialog extends PureComponent {
  constructor(props) {
    super(props);

    // Initialize the state
    this.state = {
      open: false,
      item: undefined,
      data: undefined,
      detailsError: null,
      form: {
        details: ''
      }
    };

    // Bind component functions
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.confirm = this.confirm.bind(this);
    this.updateFormData = this.updateFormData.bind(this);
    this.handleDetailsChange = this.handleDetailsChange.bind(this);

    // Create the events object
    this.events = {
      close: this.close,
      confirm: this.confirm
    };
  }

  // Create a open function to hook into in
  // the parent component
  open(item, data) {
    this.setState({
      open: true,
      item,
      data
    });
  }

  // Create a close function to hook into in
  // the parent component
  close() {
    this.setState({
      open: false
    });
  }

  confirm() {
    // Grab the onConfirm function from the props
    const { onConfirm } = this.props;
    const { item, form } = this.state;
    // Make sure the user has entered in the message
    const detailsLength = form.details.length;
    if (
      form.details &&
      detailsLength >= 0 &&
      (detailsLength < 5 || detailsLength > 200)
    ) {
      // If not, show an error
      this.setState({
        detailsError: 'Your message must be between 5 and 200 characters.'
      });
      return;
    }

    // Trigger the onConfirm function
    this.close();
    this.props?.auth?.role === 'student'
      ? onConfirm(item)
      : onConfirm(item, form);
  }

  updateFormData(key, value) {
    // Get the form data from the state
    const { form } = this.state;

    // Update the form data
    this.setState({
      form: {
        ...form,
        [key]: value
      }
    });
  }

  handleDetailsChange(event) {
    this.updateFormData('details', event.target.value);
  }

  render() {
    // Get whether the dialog is open
    const { open, data, form, detailsError } = this.state;

    return (
      <ResponsiveDialog
        title={`${data && data.action} ${data && data.item}`}
        events={this.events}
        open={open}
        confirmRed>
        {data && data.content ? (
          <>
            <Typography variant="subtitle2">
              <b>{data && data.content}</b>
            </Typography>
            <TextField
              style={{ marginTop: 10 }}
              label="Details"
              multiline
              rows={3}
              value={form.details}
              onChange={this.handleDetailsChange}
              variant="outlined"
              error={detailsError != null}
              fullWidth
            />
            <FormHelperText error>{detailsError}</FormHelperText>
          </>
        ) : (
          <>
            <Typography variant="subtitle1">
              {`Are you sure you wish to ${data && data.action} ${
                data && data.prefix
              } ${
                data?.agreementName ? data.agreementName : data && data.item
              }?`}
            </Typography>
            <br />
            <Typography variant="subtitle1">
              <b>{data && data.message && data.message}</b>
            </Typography>
          </>
        )}
      </ResponsiveDialog>
    );
  }
}

export default ConfirmDialog;
