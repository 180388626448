import React from 'react';
import {
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  useTheme,
  useMediaQuery,
  makeStyles
} from '@material-ui/core';
import moment from 'moment-timezone';

const useStyles = makeStyles((theme) => ({
  header: {
    padding: theme.spacing(2)
  },
  dialogContent: {
    paddingTop: theme.spacing(2),
    textAlign: 'center'
  },
  infoBox: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius
  },
  confirmationMessage: {
    fontSize: '16px',
    fontWeight: 500,
    color: theme.palette.text.primary,
    marginBottom: theme.spacing(2)
  },
  lessonDetails: {
    marginTop: theme.spacing(1)
  }
}));

const GroupSessionBookingDialog = ({ open, onClose, booking, onConfirm }) => {
  const theme = useTheme();
  const classes = useStyles();

  // Handle the submission (confirm booking)
  const handleSubmit = () => {
    if (onConfirm) {
      onConfirm(booking);
    }
    onClose();
  };

  return (
    <Dialog
      fullScreen={useMediaQuery(theme.breakpoints.down('sm'))}
      maxWidth="md"
      fullWidth
      open={open}
      onClose={onClose}>
      <div className={classes.header}>
        <Typography variant="h3">Booking Confirmation</Typography>
      </div>

      <DialogContent className={classes.dialogContent}>
        {/* Confirmation message */}
        <Typography variant="h6" className={classes.confirmationMessage}>
          You’re all set for Group Sessions!
        </Typography>

        {/* Booking Details Box */}
        <div className={classes.infoBox}>
          <Typography variant="body1">
            You’ve successfully selected{' '}
            <strong>
              {booking?.courseName} - Level {booking?.lessonLevel}{' '}
            </strong>{' '}
            Group Session!
          </Typography>
          <Typography variant="body1">
            This Group Session will run for{' '}
            <strong>{booking?.lessonCount} lessons</strong>, starting at{' '}
            {/* show time from booking.when */}
            <strong>{moment(booking?.when).format('hh:mm A')}</strong> on{' '}
            <strong>
              {moment(booking?.when).format('dddd, MMMM D, YYYY')}
            </strong>
            .
            {/* <strong>9:30 am</strong> on{' '}
            <strong>Monday, November 18, 2024</strong>. */}
          </Typography>
          <Typography variant="body1" className={classes.lessonDetails}>
            We’re excited to help you build your skills—see you in class!
          </Typography>
        </div>
      </DialogContent>

      <DialogActions>
        {/* Confirm Button */}
        <Button
          variant="outlined"
          color="primary"
          onClick={handleSubmit}
          disabled={!booking}>
          Confirm
        </Button>

        {/* Cancel Button */}
        <Button variant="outlined" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default GroupSessionBookingDialog;
